import { swaggerSplitApi as api, profile } from "../swaggerApi"
import type {
  PagebleResponse,
  UserEventInfo,
  UserInfoWritable,
  UserOrganizationEventsArgs,
  userProfile,
  userProfileArgs,
  UserProfileUpdate,
  UserProfileDelete,
} from "./types"
import { setProfileInfo, setSubscribeData } from "./userDataSlice"
import { avatarUrlDecorator } from "../avatarUrl"

export const userProfileApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getUserProfile: build.query<userProfile, userProfileArgs>({
      query: (args) => ({ url: `/api/v1/user/profile/${args.userId}` }),
      providesTags: [...profile],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data)
          dispatch(
            setProfileInfo({
              isPromoter_or_Cashier: data.is_promoter_or_cashier,
              age: data.age,
              avatar_url: avatarUrlDecorator(data.avatar_url) ?? "",
              full_name: data.full_name,
              username: data.username,
              id: data.user_id,
            }),
          )
          dispatch(
            setSubscribeData({
              subscribeData: {
                subscribers: data.followers_count,
                subscription: data.followings_count,
              },
            }),
          )
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getUserOrganizationEvents: build.query<
      PagebleResponse<UserEventInfo>,
      UserOrganizationEventsArgs
    >({
      query: (arg) => ({
        url: `/api/v1.1/events/organisation/${arg.userId}?page=${arg.page}&size=${arg.size}&sort=${arg.sort}`,
      }),
      providesTags: [...profile],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data.content)
        } catch (error) {
          console.log(error)
        }
      },
    }),
    getUserParticipantEvents: build.query<
      PagebleResponse<UserEventInfo>,
      UserOrganizationEventsArgs
    >({
      query: (arg) => ({
        url: `/api/v1.1/events/participant/${arg.userId}?page=${arg.page}&size=${arg.size}&sort=${arg.sort}`,
      }),
      providesTags: [...profile],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          console.log(data.content)
        } catch (error) {
          console.log("Error - participant")
          console.log(error)
        }
      },
    }),
    getUserInvitationEvents: build.query<Array<UserEventInfo>, userProfileArgs>(
      {
        query: (arg) => ({ url: `/api/v1/events/invitation/${arg.userId}` }),
        providesTags: [...profile],
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled
          } catch (error) {}
        },
      },
    ),
    getUserInfoWritable: build.query<UserInfoWritable, userProfileArgs>({
      query: (args) => ({
        url: `/api/v1/user/${args.userId}`,
      }),
      providesTags: [...profile],
      transformResponse: (res: UserInfoWritable) => {
        res.avatar_url = avatarUrlDecorator(res.avatar_url) ?? ""
        return res
      },
    }),
    updateUserSettings: build.mutation<object, UserProfileUpdate>({
      query: (arg) => ({
        url: `/api/v1/user`,
        method: "PATCH",
        body: arg,
      }),
      invalidatesTags: [...profile],
    }),
    deleteUserProfile: build.mutation<object, UserProfileDelete>({
      query: ({ userId }) => ({
        url: `/api/v1/user/delete/${userId}`,
        method: "DELETE",
      }),
      invalidatesTags: [...profile],
    }),
  }),
})

export const {
  useGetUserProfileQuery,
  useGetUserOrganizationEventsQuery,
  useGetUserParticipantEventsQuery,
  useGetUserInvitationEventsQuery,
  useGetUserInfoWritableQuery,
  useUpdateUserSettingsMutation,
  useDeleteUserProfileMutation,
} = userProfileApi
