import { filterToURLSearchParams } from "../../../shared/filter/filterBuilderParams/filterToSearchParams";
import { FilterType } from "../../../shared/filter/types";

const keyQuery = (key: string, value: number | boolean | string): string => {
  return `${key}=${value}`
}
const collectQuery = (items: string[]): string => {
  return items.join("&")
}

const arrayQuery = (key: string, array: number[]): string => {
  return collectQuery(array.map((item) => keyQuery(key, item)))
}

const propToQuery = (key: string, value: any): string => {
  if (
    typeof value === "number" ||
    typeof value === "string" ||
    typeof value === "boolean"
  ) {
    return keyQuery(key, value)
  }
  if (typeof value === "object") return arrayQuery(key, value)
  return ""
}

export const buildQuery = (value: FilterType): string => {

  const url = filterToURLSearchParams(value)

  return url.toString()
}
