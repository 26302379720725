import { Theme } from "@emotion/react"
import { SxProps } from "@mui/material"
import { btnStyle } from "../SearchParticipantsCard/style"

export const contentCont = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  minHeight: "390px",
  width: "100%",
}

export const avatar = {
  width: "100px",
  height: "100px",
  borderRadius: "50%",
}

export const btnCont = {
  display: "flex",
  justifyContent: "space-between",
  gap: "16px",
  width: "100%",
  height: "100%",
  alignItems: "flex-end",
}

export const btnBack = { width: "65px", minWidth: "152px", height: "auto" }

export const btnSubmit = {
  ...btnStyle,
  minWidth: "unset",
  maxWidth: "unset",
  width: "100%",
}

export const contactInfoStyle: SxProps<Theme> = {
  display: "flex",
  gap: "4px",
  flexDirection: "column",
  color: "var(--primary-text)",
  overflowWrap: "anywhere",
}
