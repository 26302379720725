import React, { FC } from "react";
import { List, ListItem, ListItemButton, ListItemText, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

const GeoMenu:FC = () => {
  return <Card>
    <Typography sx={{ p: 2 }}>Ваш город</Typography>
    <Divider />

    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText primary={"Москва"} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton>
          <ListItemText primary={"Томск"} />
        </ListItemButton>
      </ListItem>
    </List>
  </Card>
}
export default GeoMenu