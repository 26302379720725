import Box from "@mui/material/Box"
import Toolbar from "@mui/material/Toolbar"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { useGetLocationQuery } from "../../app/data/location/locationApi"
import { MenuButton } from "./HeaderMenu/MenuButton/MenuButton"
import { HeaderIcons } from "./HeaderProfile/HeaderIcons"
import HeaderSearch from "./HeaderSearch"
import {
  geolocationStyle,
  headerCont,
  headerLeftCont,
  headerRightCont,
  headerStyle,
  logoLink,
} from "./headerNavStyles"
import geolocationIcon from "/src/assets/svg/header-icons-pack/geolocation.svg"
import logo from "/src/assets/svg/header-icons-pack/logo-icon.svg"
import BackArrow from "/src/assets/svg/promotion-icon/back-arrow.svg?react"
import PromoTitle from "/src/assets/svg/promotion-icon/promotion-title.svg?react"
import GetButton from "./GetButton";

const Header = () => {
  const screenUserWidth = window.screen.width
  const { data } = useGetLocationQuery()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return (
    <Box sx={headerCont}>
      <Toolbar sx={headerStyle}>
        <Box sx={headerLeftCont}>
          <BackArrow
            onClick={() => navigate(-1)}
            style={{
              cursor: "pointer",
              display: !pathname.startsWith("/profile/promotion")
                ? "none"
                : "block",
            }}
          />

          <MenuButton />

          {screenUserWidth > 759 && (
            <Box sx={{ display: "flex" }}>
              <Link to="/" style={logoLink}>
                <img
                  src={logo}
                  alt="Логотип Tribe"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                />
              </Link>

              <PromoTitle
                style={{
                  alignSelf: "end",
                  marginBottom: "3px",
                  width: "64px",
                  display: !pathname.startsWith("/profile/promotion")
                    ? "none"
                    : "block",
                }}
              />
            </Box>
          )}
        </Box>

        <Box sx={headerRightCont}>
          {screenUserWidth > 759 && (
            <GetButton/>
          )}

          <HeaderSearch />

          <HeaderIcons />
        </Box>
      </Toolbar>
    </Box>
  )
}

export default Header
