import CloseIcon from "@mui/icons-material/Close"
import { IconButton } from "@mui/material"
import { useFormik } from "formik"
import { FC, useEffect, useState } from "react"
import { selectUserId } from "../../../app/data/appTokensStore"
import { useAppSelector } from "../../../app/hooks"
import { useTheme } from "../../../hooks/useTheme"
import { TMakeStepChangeVariables, TUser } from "../types"
import { RequestTemplateForCreatingEventDto } from "../types/schema"
import Confirm from "./stepers/confirm"
import InviteConfirm from "./stepers/inviteConfirm"
import Step1 from "./stepers/step_1"
import Step2 from "./stepers/step_2"
import Step3 from "./stepers/step_3"
import Step4 from "./stepers/step_4"
import Step5 from "./stepers/step_5"
import Step6 from "./stepers/step_6"

// toISOString
type TLayoutEventProps = {
  handlerClose: () => void
}

const LayoutEvent: FC<TLayoutEventProps> = ({ handlerClose }) => {
  const { isDarkTheme } = useTheme()

  const userId = useAppSelector(selectUserId)
  const [invitedUsers, setInvitedUsers] = useState<Array<TUser>>([])
  const [photo, setPhoto] = useState<Array<string>>([])
  const [step, setStep] = useState<number | string>(1)

  const formik = useFormik<RequestTemplateForCreatingEventDto>({
    initialValues: {
      event_name: "",
      event_address: {
        city: "",
        region: "",
        street: "",
        event_longitude: 0,
        event_latitude: 0,
        house_number: "",
      },
      start_time: "",
      event_type_id: 0,
      end_time: "",
      description: "",
      show_event_in_search: true,
      is_private: false,
      has_alcohol: false,
      send_to_all_users_by_interests: false,
      is_eighteen_year_limit: false,
      organizer_id: 0,
      is_free: true,
      time_zone: "",
    },
    onSubmit: (values) => {},
  })
  useEffect(() => {
    if (userId) formik.setFieldValue("organizer_id", userId)
  }, [userId])

  const makeStep = (variant: TMakeStepChangeVariables) => {
    if (variant === "+") {
      // @ts-ignore
      setStep((prev) => (prev + 1 > 5 ? 5 : prev + 1))
    } else if (variant === "-") {
      // @ts-ignore
      setStep((prev) => (prev - 1 < 1 ? 1 : prev - 1))
    } else {
      setStep(variant)
    }
  }

  const toggleUsersList = (choosenUser: TUser) => {
    const isUserAlreadyInvited = invitedUsers.some(
      (user) => user.id === choosenUser.id,
    )

    if (isUserAlreadyInvited) {
      setInvitedUsers((prev) =>
        prev.filter((user) => user.id !== choosenUser.id),
      )
      return
    }
    setInvitedUsers((prev) => [...prev, choosenUser])
  }

  const addPhoto = (imgArrBuf: string, imgDataUrl: string, pos: number) => {
    const updatedPhoto = [...photo]
    updatedPhoto[pos] = imgDataUrl
    setPhoto((prev) => updatedPhoto)
    formik.setFieldValue(`avatars_for_adding[${pos}]`, imgArrBuf)
  }
  return (
    <>
      {step === 1 && <Step2 formik={formik} step={step} makeStep={makeStep} />}
      {step === 2 && <Step1 formik={formik} step={step} makeStep={makeStep} />}
      {/*{step === 3 && <Step1 formik={formik} step={step} makeStep={makeStep} />}*/}
      {step === 3 && <Step6 formik={formik} step={step} makeStep={makeStep} />}
      {step === 4 && (
        <Step3
          addPhoto={addPhoto}
          photo={photo}
          formik={formik}
          step={step}
          makeStep={makeStep}
        />
      )}
      {step === 5 && (
        <Step5
          formik={formik}
          step={step}
          makeStep={makeStep}
          invitedUsers={invitedUsers}
          handlerClose={handlerClose}
          photo={photo[0]}
        />
      )}
      {step === "confirm" && (
        <Confirm
          handlerClose={handlerClose}
          formik={formik}
          step={step}
          makeStep={makeStep}
        />
      )}
      {step === "invite" && (
        <Step4
          handlerClose={handlerClose}
          formik={formik}
          step={step}
          makeStep={makeStep}
          toggleUsersList={toggleUsersList}
          invitedUsers={invitedUsers}
        />
      )}
      {step === "invite_confirm" && (
        <InviteConfirm
          handlerClose={handlerClose}
          formik={formik}
          step={step}
          makeStep={makeStep}
        />
      )}
    </>
  )
}

export default LayoutEvent
