import ClearIcon from "@mui/icons-material/Clear"
import { Box } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"
import Filtered from "../../filtered"
import { handleVisiblity } from "../headerNavStyles"
import {
  deleteIcon,
  filterForHeader,
  searchCont,
  searchIcon,
  searchInput,
} from "./headerSearchStyles"
import SearchIcon from "/src/assets/svg/header-icons-pack/Search-icon.svg?react"
import filterIcon from "/src/assets/svg/header-icons-pack/filter.svg"
import activeFilterIcon from "/src/assets/svg/header-icons-pack/filter-active.svg"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter"

const HeaderSearch = () => {
  const { pathname } = useLocation()
  const screenUserWidth = window.screen.width
  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const rightPath = ["/", "/map"].some((p) => p === pathname)
  const inputFocus = useRef<HTMLInputElement | null>(null)

  const { updateQueryText, currentFilter } = useEventFilter()
  const { text: inputText } = currentFilter
  const handleInputFocus = () => {
    if (inputFocus.current) {
      inputFocus.current.focus()
    }
  }

  const handleSearchQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value
    updateQueryText(text)
  }

  useEffect(() => {
    if (isOpenFilter) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }

    return () => {
      document.body.style.overflow = "auto"
    }
  }, [isOpenFilter])

  return (
    <Box sx={searchCont}>
      <input
        type="search"
        placeholder="Поиск..."
        style={{
          ...searchInput,
          color: inputText ? "#c9c5d0" : "#47464f",
        }}
        value={currentFilter.text}
        onChange={handleSearchQuery}
        ref={inputFocus}
      />

      <SearchIcon
        style={{
          ...searchIcon,
          color: inputText ? "#c9c5d0" : "#47464f",
        }}
        onClick={handleInputFocus}
      />

      {screenUserWidth < 760 && !inputText && rightPath && (
        <>
          <img
            src={activeFilterIcon}
            alt="Фильтр"
            onClick={() => setIsOpenFilter(false)}
            style={handleVisiblity("filter-on", isOpenFilter)}
          />

          <img
            src={filterIcon}
            alt="Фильтр"
            onClick={() => setIsOpenFilter(true)}
            style={handleVisiblity("filter-off", isOpenFilter)}
          />
        </>
      )}

      <ClearIcon
        onClick={() => updateQueryText("")}
        sx={deleteIcon}
        style={handleVisiblity("delete", !!inputText)}
      />

      {screenUserWidth < 760 && rightPath && (
        <Filtered
          extraStyles={filterForHeader(isOpenFilter)}
          setIsOpenFilter={setIsOpenFilter}
          isOpenFilter={isOpenFilter}
        />
      )}
    </Box>
  )
}

export default HeaderSearch
