export type ChooseDayStartType = //NIGHT, AFTERNOON, MORNING, EVENING
  | "MORNING"
  | "AFTERNOON"
  | "EVENING"
  | "NIGHT"
  | undefined

export type SortType = "DATE" | "DISTANCE" | "ALCOHOL"
export type PartsOfDay = PartOfDay[]
export type PartOfDay = "MORNING" | "AFTERNOON" | "EVENING" | "NIGHT"
export type NumberRange = [number | undefined, number | undefined]
export type TimeRange = [string , string] | [undefined, undefined]
export type FilterType = {
  text?: string
  eventTypeId?: number[]
  distanceInMeters?: number
  longitude?: number
  latitude?: number
  startDate?: string
  endDate?: string
  zoneId?: string
  numberOfParticipantsMin?: number
  numberOfParticipantsMax?: number
  partsOfDay?: PartsOfDay
  durationEventInHoursMin?: number
  durationEventInHoursMax?: number
  isPresenceOfAlcohol?: boolean
  isFree?: boolean
  isFinished?: boolean
  isEighteenYearLimit?: boolean
  sort?: SortType
  strictEventSort?: boolean
  order?: "ASC" | "DESC"
  city?: string
}

export const defaultFilter = (): FilterType => {
  return {
    zoneId: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isFinished: false,
  }
}