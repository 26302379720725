// Начальное состояние
import { CategoriesState } from "./types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CategoryInfo } from "../../components/filtered/types";

const initialState: CategoriesState = {
  categories: [],
  selectedCategories: [],
  loading: true,
  error: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState: initialState,
  reducers: {
    toggleCategorySelection: (state, action: PayloadAction<number>) => {
      const categoryId = action.payload;
      const category = state.categories.find((cat) => cat.id === categoryId);

      if (!category) return;

      if (state.selectedCategories.some((selectedCat) => selectedCat.id === categoryId)) {
        // Если категория уже выбрана, удаляем её
        state.selectedCategories = state.selectedCategories.filter(
          (selectedCat) => selectedCat.id !== categoryId
        );
      } else {
        // Иначе добавляем её
        state.selectedCategories.push(category);
      }
    },
    selectAllCategories: (state) => {
      // Выбираем все категории
      state.selectedCategories = [...state.categories];
    },
    clearSelectedCategories: (state) => {
      // Очищаем выбранные категории
      state.selectedCategories = [];
    },
    setCategories: (state, action:PayloadAction<CategoryInfo[]>) => {
      state.categories = action.payload;
      state.loading = false
    }
  }
})
export const {setCategories, clearSelectedCategories, toggleCategorySelection, selectAllCategories} = categoriesSlice.actions
export default categoriesSlice.reducer;
