import ViewOnIcon from "../../../../assets/svg/viewOn.svg?react"
import ViewOffIcon from "../../../../assets/svg/viewOff.svg?react"
import { FC, useState } from "react"
import s from "./styles.module.css"
import classNames from "classnames"

interface MyFormValues {
  type: string
  placeholder: string
  value: string
  handleChange: (e: any) => void
  name: string
  title?: string
  onBlur: (e: any) => void
  searchClick?: (e: any) => void
  errorText: string | null
  isSearch?: boolean
  className?: any
  autocomplete?: string
}

const InputPassword: FC<MyFormValues> = ({
  type = "text",
  errorText,
  onBlur,
  placeholder,
  className,
  name,
  value,
  autocomplete,
  title = null,
  handleChange,
  isSearch = false,
  searchClick,
}) => {
  const [visible, setVisible] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <div style={{ width: "100%" }}>
      {title && <span className={s.title}>{title}</span>}
      <div className={s.input_box}>
        <input
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          className={classNames(s.input, className)}
          type={!visible ? type : "text"}
          placeholder={placeholder}
          autoComplete={autocomplete}
        />
        {type === "password" && (
          <div
            onClick={(e) => {
              setVisible(!visible)
            }}
            className={s.input_btn}
          >
            {!visible ? <ViewOffIcon /> : <ViewOnIcon />}
          </div>
        )}
      </div>
      {errorText && <p className={s.error_text}>{errorText}</p>}
    </div>
  )
}

export default InputPassword
