import classNames from "classnames"
import { useRef } from "react"
import { createPortal } from "react-dom"
import { Link } from "react-router-dom"

import { setEventData } from "../../../app/data/popupSlice/popupSlice"
import { useSendRequestToParticipationInPublicEventMutation } from "../../../app/data/tribeSwaggerApi"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import useFocusLockModal from "../../../hooks/useFocusLockModal"
import { useIsAuthorizedUser } from "../../../hooks/useIsAuthorizedUser"
import { show } from "../../../pages/signIn/SignInSlice"

import { EventModel } from "../../../types"
import CardEvent, { CardEventFormats } from "../../CardEvent/CardEvent"
import GradientButton, { GradienButtonStyles } from "../../GradientStateBtn"

import BtnClose from "../../../assets/svg/close.svg?react"
import Telegram from "../../../assets/svg/socials-icons/Telegram.svg?react"
import LocationSpinner from "../../../common/ui-kit/spinner"
//import Instagram from "../../../assets/svg/socials-icons/Instagram.svg?react"
import Skype from "../../../assets/svg/socials-icons/Skype.svg?react"
import VK from "../../../assets/svg/socials-icons/Vkontakte.svg?react"
import WatsApp from "../../../assets/svg/socials-icons/WhatsApp.svg?react"

import s from "../EventDialog/styles.module.scss"

export enum EventDialogFormats {
  share = "Share",
  invite = "Invite",
}

type EventDialogProps = {
  isVisible: boolean
  visibleToggler: () => void
  eventLink: string
}

const getCurrentDomain = (): string => {
  const protocol = window.location.protocol
  const host = window.location.host
  return `${protocol}//${host}`
}

const EventDialog = ({
  isVisible,
  visibleToggler,
  eventLink,
}: EventDialogProps): JSX.Element | null => {
  const dispatch = useAppDispatch()
  const eventData = useAppSelector((state) => state.popupsState.eventData)
  const userId = useAppSelector((state) => state.tokenStorage.userId)
  const format = useAppSelector((state) => state.popupsState.eventDialogFormat)
  const ref = useRef<HTMLDivElement | null>(null)
  const isAuth = useIsAuthorizedUser()
  const [setParticipation, { isLoading, isError, isSuccess }] =
    useSendRequestToParticipationInPublicEventMutation()
  useFocusLockModal({ ref: ref, isVisible })
  const onClickCloseModal = () => {
    dispatch(setEventData(null))
    visibleToggler()
  }

  const textCopyHandler = (elementValue: string) => {
    navigator.clipboard.writeText(elementValue)
  }

  if (eventData === null) {
    return null
  }

  const onClickWantToGo = async () => {
    if (!isAuth) {
      visibleToggler()
      dispatch(show())
    } else {
      await setParticipation({
        eventId: eventData.id,
        userId: userId?.toString()!!, // TODO(Костыль привединия типов надо переделать)
      }).unwrap()
      visibleToggler()
    }
  }

  return createPortal(
    <div className={classNames(s.modal_overlay, !isVisible && s.modal_hidden)}>
      <div className={s.modal_body} ref={ref}>
        <BtnClose
          className={s.modal_btn_close}
          onClick={() => onClickCloseModal()}
        />
        <div className={s.modal_guts}>
          <div className={s.modal_header}>
            <h2 className={s.modal_title}>
              {format === EventDialogFormats.share
                ? "Поделиться"
                : "Подтверждение участия"}
            </h2>
          </div>
          <div
            className={
              s.modal_content +
              (format === EventDialogFormats.share
                ? ` ${s.modal_content_share}`
                : ` ${s.modal_content_invite}`)
            }
          >
            {format === EventDialogFormats.share ? (
              <>
                <div className={s.modal_card_container}>
                  {eventData ? (
                    <CardEvent
                      event={eventData as EventModel}
                      format={CardEventFormats.ModalThumb}
                    />
                  ) : (
                    isLoading && <LocationSpinner />
                  )}
                </div>
                <Link to="#" className={s.modal_event_link} tabIndex={1}>
                  {`${getCurrentDomain()}/#/${eventLink}`}
                </Link>
                <div className={s.modal_btns_block}>
                  <GradientButton
                    style={GradienButtonStyles.borderGradient}
                    params={{ text: "Копировать", width: 186 }}
                    onAction={() =>
                      textCopyHandler(`${getCurrentDomain()}/#/${eventLink}`)
                    }
                  />
                </div>
                <ul className={s.modal_socials}>
                  <VK />
                  {/*<Instagram />*/}
                  <WatsApp />
                  <Skype />
                  <Telegram />
                </ul>
              </>
            ) : (
              <>
                <div className={s.modal_card_container}>
                  {eventData ? (
                    <CardEvent
                      event={eventData as EventModel}
                      format={CardEventFormats.ModalThumb}
                    />
                  ) : (
                    isLoading && <LocationSpinner />
                  )}
                </div>
                <p className={s.modal_text}>
                  Нажимая на кнопку вы подтверждаете, что хотите принять участие
                  в этом событии.
                </p>
                <div className={s.modal_btns_block}>
                  <GradientButton
                    fetchIsLoading={isLoading}
                    style={GradienButtonStyles.fullGradient}
                    params={{ text: "Подтведить", width: 186 }}
                    onAction={() => onClickWantToGo()}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>,
    document.body,
  )
}

export default EventDialog
