import { GeoCity, GeoProviderReturnType } from "./types";
import { useEffect, useState } from "react";
import { useGetLocationQuery } from "../../app/data/location/locationApi";

const defaultCity:GeoCity = {
  city: "Москва", country: "", latitude: 55.751244, longitude: 37.618423

}
const useGeoProvider = ():GeoProviderReturnType => {
  const [city, setCity] = useState(defaultCity)
  const { data } = useGetLocationQuery()
  useEffect(() => {
    if (data) {
      setCity(data)
    }
  }, [data]);

  return [city, setCity]
}
export default useGeoProvider