import { useState } from "react"

export const useGetCityFromGeolocation = (): {
  city: string
  getCity: () => Promise<void>
} => {
  const [city, setCity] = useState<string>("")

  const getPosition = (): Promise<GeolocationPosition> =>
    new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject, {
        enableHighAccuracy: false,
        timeout: 3600,
        maximumAge: 1000,
      })
    })

  const fetchCityName = async (
    position: GeolocationPosition,
  ): Promise<string> => {
    //TODO:заменить внутренности, когда Никита отдаст ручку с городами
    const { latitude, longitude } = position.coords
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&accept-language=ru`

    const response = await fetch(url, {
      cache: "force-cache",
      referrerPolicy: "no-referrer",
    })
    const { address } = await response.json()

    return address.city ?? address.town ?? address.village ?? "Москва"
  }

  const retryGetCity = async () => {
    if (
      navigator.permissions &&
      (await navigator.permissions.query({ name: "geolocation" })).state ===
        "granted"
    ) {
      try {
        const position = await getPosition()
        const cityName = await fetchCityName(position)
        setCity(cityName)
      } catch {
        // Do nothing if geolocation is still not allowed
      }
    }
  }

  const getCity = async (): Promise<void> => {
    try {
      const position = await getPosition()
      const cityName = await fetchCityName(position)
      setCity(cityName)
    } catch (error: any) {
      const errorMessage =
        error.code === 1
          ? "Привет! 👋 Хочешь узнать о крутых событиях в твоем городе? \n\nЧтобы мы могли подобрать что-нибудь интересное, давай узнаем, где ты находишься. Для этого разреши своему браузеру поделиться твоим местоположением. 🗺 \n\nНе хочешь делиться?  Ничего страшного!  Тогда мы покажем события в Москве. 😉 "
          : `Ошибка геолокации: ${error.message}`
      alert(errorMessage)
      setCity("Москва")
      setTimeout(retryGetCity, 30000) //Пробуем еще один раз через 30 секунд в надежде, что пользователь одумался и включил геолокацию в браузере
      return
    }
  }

  return { city, getCity }
}
