import { FC } from "react"
import s from "../styles.module.css"
import PasswordIcon from "../../../assets/svg/password_icon.svg?react"
import { useFormik } from "formik"
import InputPassword from "../../../common/ui-kit/inputs/input_password"
import { useChangePasswordMutation } from "../../../app/data/tribeSwaggerApi"
import { useAppSelector } from "../../../app/hooks"
import { selectUserId } from "../../../app/data/appTokensStore"
import GradientBtn from "../../../components/GradientBtn"

interface Step3ResetPassForm {
  password: string
  rePassword: string
}
type StepProps = {
  back: () => void
  code: number
}

const Step3: FC<StepProps> = ({ code, back }) => {
  const [newPassword, { isError }] = useChangePasswordMutation()
  const selectUser = useAppSelector(selectUserId)
  const formik = useFormik<Step3ResetPassForm>({
    initialValues: {
      password: "",
      rePassword: "",
    },
    validate: (values) => {
      const errors: Partial<Step3ResetPassForm> = {}

      if (!values.password) {
        errors.password = "Обязательное поле"
      }
      if (!values.rePassword) {
        errors.rePassword = "Обязательное поле"
      } else if (values.password !== values.rePassword) {
        errors.rePassword = "Пароли не совпадают"
      }

      return errors
    },
    onSubmit: (values) => {
      if (selectUser && code) {
        newPassword({
          changePasswordDto: {
            user_id: Number(selectUser),
            old_password: code.toString(),
            new_password: values.password,
          },
        })
          .unwrap()
          .then((value) => {
            back()
          })
      }
    },
  })

  return (
    <form className={s.step}>
      <div className={s.icon}>
        <PasswordIcon />
      </div>
      <p className={s.title_step_3}>Новый пароль</p>
      <div className={s.input_box}>
        <InputPassword
          errorText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
          name={"password"}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"password"}
          placeholder={"новый пароль"}
        />
        <InputPassword
          errorText={
            formik.touched.password && formik.errors.rePassword
              ? formik.errors.rePassword
              : null
          }
          name={"rePassword"}
          value={formik.values.rePassword}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"password"}
          placeholder={"повторите пароль"}
        />
      </div>
      <div className={s.confirm_reset}>
        <GradientBtn
          type="submit"
          //disabled={formik.errors}
          title="Сохранить пароль"
          aria-label="register new password"
          btnStyle={{
            width: "fit-content",
            height: "36px",
            minWidth: "128px",
            padding: "12px 16px",
            textTransform: "none",
          }}
          handleAction={formik.handleSubmit}
        />
      </div>
    </form>
  )
}

export default Step3
