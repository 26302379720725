import { SxProps, Theme } from "@mui/material"

export const tabsContainer: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  padding: "4px 0 16px",
}

export const tabItem: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "24px 1fr",
  width: "100%",
  height: "40px",
  minHeight: "40px",
  gap: "8px",
  margin: "12px 0",
  padding: "8px 16px",
  alignItems: "center",
  borderRadius: "8px",
  color: "var(--secondary-text)",
  textAlign: "left",
  textTransform: "none",
  fontSize: "14px",
  fontWeight: "500",
  lineHeight: "20px",
  transition: "all 0.3s ease",
  cursor: "pointer",
  "&:hover, &.Mui-selected": {
    color: "var(--filter-range-active)",
    backgroundColor: "var(--settings-tab-hover)",
    transition: "all 0.3s ease",
  },
  "&::after, &::before": {
    content: "''",
    position: "absolute",
    right: "16px",
    backgroundColor: "var(--secondary-text)",
    height: "2px",
    width: "8.5px",
    borderRadius: "16px",
    transition: "backgroundColor 0.3s ease",
  },
  "&::after": {
    transform: "translateY(-140%) rotate(45deg)",
  },
  "&::before": {
    transform: "translateY(140%) rotate(-45deg)",
  },
  "&:hover::after, &:hover::before, &.Mui-selected::after, &.Mui-selected::before":
    {
      backgroundColor: "var(--filter-range-active)",
      transition: "backgroundColor 0.3s ease",
    },
}

export const tabList: SxProps<Theme> = {
  paddingTop: "12px",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
}

export const listItem: SxProps<Theme> = {
  minHeight: "40px",
}

export const listButton: SxProps<Theme> = {
  display: "grid",
  gridTemplateColumns: "24px 1fr auto",
  width: "100%",
  height: "100%",
  gap: "8px",
  padding: "8px 16px",
  alignItems: "center",
  borderRadius: "8px",
  color: "var(--secondary-text)",
  textAlign: "left",
  textTransform: "none",
  transition: "all 0.3s ease",
  cursor: "pointer",

  "&:hover": {
    color: "var(--filter-range-active)",
    backgroundColor: "var(--settings-tab-hover)",
  },
  "&:active": {
    backgroundColor: "var(--settings-tab-hover)",
  },
  span: {
    padding: "0",
    fontFamily: "Roboto, sans-serif",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "24px",
    textAlign: "left",
    color: "var(--filter-range-active)",
  },
}
