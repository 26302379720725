import { Checkbox } from "@mui/material"
import { Link } from "react-router-dom"
import { EventsCategoryActions } from "../../hooks/profile_page_hooks/useProfileEventFieldData"
import { CalendarIcon } from "../../assets/svg/profile/CalendarIcon"
import { IWillGoIcon } from "../../assets/svg/profile/IWillGoIcon"
import { TicketIcon } from "../../assets/svg/profile/TicketIcon"
import { CalendarIconChecked } from "../../assets/svg/profile/CalendarIconChecked"
import { IWillGoIconChecked } from "../../assets/svg/profile/IWillGoIconChecked"
import { TicketIconChecked } from "../../assets/svg/profile/TicketIconChecked"
import { actionsCheckbox } from "./profileCheckboxStyle"
import s from "./styles.module.scss"

type ProfileCheckBoxProps = {
  setEventType: (evtType: EventsCategoryActions) => void
  activeType: EventsCategoryActions
}

const ProfileCheckBox = ({
  setEventType,
  activeType,
}: ProfileCheckBoxProps) => {
  const handleClick = (type: EventsCategoryActions) => () => setEventType(type)

  const checkboxes = [
    {
      label: "Мои события",
      type: "myevents",
      Icon: CalendarIcon,
      CheckedIcon: CalendarIconChecked,
    },
    {
      label: "Приглашения",
      type: "invites",
      Icon: TicketIcon,
      CheckedIcon: TicketIconChecked,
    },
    {
      label: "Я пойду",
      type: "iwillgo",
      Icon: IWillGoIcon,
      CheckedIcon: IWillGoIconChecked,
    },
  ]

  return (
    <div className={s.user_actions}>
      {checkboxes.map(({ label, type, Icon, CheckedIcon }) => (
        <Link
          to={type}
          key={type}
          onClick={handleClick(type as EventsCategoryActions)}
        >
          <label key={type} className={s.user_actions_item}>
            <Checkbox
              sx={actionsCheckbox}
              icon={<Icon />}
              checkedIcon={<CheckedIcon />}
              checked={activeType === type}
              readOnly
            />
            {label}
          </label>
        </Link>
      ))}
    </div>
  )
}

export default ProfileCheckBox
