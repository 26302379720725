import { Theme } from "@mui/system"
import { SxProps } from "@mui/material"

export const styles: SxProps<Theme> = {
  fontFamily: "Roboto, sans-serif",
  fontSize: "16px",
  borderRadius: "8px",
  backgroundColor: "var(--inputs-bg)",
  minHeight: "40px",
  alignItems: "center",
  justifyContent: "center",
  padding: "2px 8px",
  transition: "all 0.3s ease",
  input: {
    color: "var(--secondary-text)",
    "&::placeholder": {
      color: "var(--input-placeholder",
    },
  },
}
