import { FC, useState } from "react"
import { useFormik } from "formik"
import useSingIn from "../../hooks/useSingIn"
import InputPassword from "../../common/ui-kit/inputs/input_password"
import { CheckBox } from "../checkbox/CheckBox"
import GradientBtn from "../../components/GradientBtn"
import style from "./styles.module.css"

interface AuthorizationFormProps {
  reset: (email: string) => void
  handleErrorText: (errorText: string) => void
  lock: boolean
}

interface MyFormValues {
  login: string
  password: string
}

const LoginForm: FC<AuthorizationFormProps> = (
  props: AuthorizationFormProps,
) => {
  const [checkFlag, setCheckFlag] = useState<boolean>(false)
  const [errorText, setErrorText] = useState<string | null>(null)
  const handleLogin = useSingIn(setErrorText)
  const formik = useFormik<MyFormValues>({
    initialValues: {
      login: "",
      password: "",
    },
    validate: (values) => {
      const errors: Partial<MyFormValues> = {}
      if (!values.login) {
        errors.login = "Обязательное поле"
      }
      if (!values.password) {
        errors.password = "Обязательное поле"
      }
      return errors
    },
    onSubmit: async (values) => {
      setErrorText(null)
      await handleLogin(values.login, values.password, checkFlag)
    },
  })

  if (errorText) props.handleErrorText(errorText)

  const toggleFlag = (isChecked: boolean) => {
    setCheckFlag(isChecked)
  }

  const isFormValid = formik.values.login && formik.isValid

  return (
    <form className={style.form_box}>
      <div className={style.input_container}>
        <InputPassword
          errorText={
            formik.touched.login && formik.errors.login
              ? formik.errors.login
              : null
          }
          name={"login"}
          value={formik.values.login}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"text"}
          placeholder={"Логин"}
          autocomplete={"off"}
        />
        <InputPassword
          errorText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
          name={"password"}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"password"}
          placeholder={"Пароль"}
        />
      </div>
      <CheckBox
        onChange={toggleFlag}
        text="Запомнить меня"
        checked={checkFlag}
      />
      <GradientBtn
        type="submit"
        disabled={!isFormValid}
        title="Войти"
        aria-label="login"
        btnStyle={{
          width: "fit-content",
          minWidth: "128px",
          padding: "12px 16px",
          ...(!isFormValid && {
            background: "var(--btns-disabled-bg)",
            color: "var(--btns-disabled-text)",
            pointerEvents: "none",
          }),
        }}
        handleAction={formik.handleSubmit}
      />
      <div className={style.form_additional_box}>
        Забыли пароль?
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault()
            if (!formik.values.login) {
              formik.setFieldTouched("login", true)
            } else {
              props.reset(formik.values.login)
            }
          }}
          className={style.form_link}
        >
          Сброс
        </a>
      </div>
    </form>
  )
}
export default LoginForm
