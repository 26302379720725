import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  Box,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material"

import { sideMenuSettingsTabs } from "../../../consts"
import { useProfileSettingEditor } from "../../../components/profile_settings/hooks/useProfileSetting"
import { useGetCityFromGeolocation } from "../hooks/useGetCityFromGeolocation"
import { useTheme } from "../../../hooks/useTheme"

import { IOSSwitch } from "../iosswitch/IOSSwitch"
import ColorthemeIcon from "../../../assets/svg/menu-icons-pack/fire-icon-menu.svg?react"
import GeolocationIcon from "../../../assets/svg/menu-icons-pack/geolocation-icon-menu.svg?react"
import ExitIcon from "../../../assets/svg/menu-icons-pack/exit-icon-menu.svg?react"

import {
  tabsContainer,
  tabItem,
  tabList,
  listItem,
  listButton,
} from "./ProfileSettinsTabStyles"

interface ProfileSettingsTabsProps {
  value: string
  onChange: (event: React.SyntheticEvent, newValue: string) => void
}

const ProfileSettingsTabs: React.FC<ProfileSettingsTabsProps> = ({
  value,
  onChange,
}) => {
  const [geolocationEnabled, setGeolocationEnabled] = useState<boolean>(false)
  const [themeEnabled, setThemeEnabled] = useState<boolean>(false)

  const { isDarkTheme, onToggleTheme } = useTheme()
  const { updateProfile, currentUser } = useProfileSettingEditor()
  const { getCity, city } = useGetCityFromGeolocation()

  const navigate = useNavigate()

  useEffect(() => {
    if (currentUser) {
      setGeolocationEnabled(currentUser.is_geolocation_available)
      currentUser.is_geolocation_available && getCity()
    }
  }, [currentUser])

  const handleGeolocationToggle = (): void => {
    updateProfile({ is_geolocation_available: !geolocationEnabled })
  }
  const handleThemeToggle = (): void => {
    setThemeEnabled(!themeEnabled)
    onToggleTheme()
  }

  return (
    <Box sx={tabsContainer}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        aria-label="settings tabs"
        TabIndicatorProps={{ sx: { display: "none" } }}
        value={value}
        onChange={onChange}
      >
        {sideMenuSettingsTabs.map((tab) => (
          <Tab
            sx={tabItem}
            icon={<tab.icon />}
            label={tab.label}
            iconPosition="start"
            key={tab.id}
            value={tab.id}
          />
        ))}
      </Tabs>

      <List sx={tabList} disablePadding>
        <ListItem sx={listItem} disablePadding>
          <ListItemButton
            sx={listButton}
            role={undefined}
            onClick={handleGeolocationToggle}
            dense
            disableGutters
          >
            <GeolocationIcon />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" fontWeight={500} width="100%">
                Геолокация
              </Typography>
              {geolocationEnabled && (
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "var(--secondary-text)",
                    lineHeight: "16px",
                    minHeight: "16px",
                  }}
                >
                  {city}
                </Typography>
              )}
            </div>
            <IOSSwitch sx={{ m: 0 }} checked={geolocationEnabled} />
          </ListItemButton>
        </ListItem>

        <ListItem sx={listItem} disablePadding>
          <ListItemButton
            sx={listButton}
            role={undefined}
            onClick={handleThemeToggle}
            dense
            disableGutters
          >
            <ColorthemeIcon />
            <Typography variant="body2" fontWeight={500} width="100%">
              Тема
            </Typography>
            <span>{isDarkTheme ? "Темная" : "Светлая"}</span>
          </ListItemButton>
        </ListItem>

        <ListItem sx={listItem} disablePadding>
          <ListItemButton
            sx={listButton}
            role={undefined}
            onClick={() => navigate("/")}
            dense
            disableGutters
          >
            <ExitIcon />
            <Typography variant="body2" fontWeight={500} width="100%">
              Выход из настроек
            </Typography>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )
}

export default ProfileSettingsTabs
