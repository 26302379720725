import { Avatar } from "@mui/material"
import { useEffect, useState } from "react"
import { SendWhatsAppData, ViewsNames } from "../.."
import {
  CreateTicketForParticipantData,
  usePatchTicketForParticipant,
  UserDataForAddToBaseParticipant,
} from "../../../../../app/data/promotion/promotionApi"
import QrCode from "../../../../../assets/Qrcode.svg?react"
import AvatarFallback from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import Telegram from "../../../../../assets/svg/socials-icons/Telegram.svg?react"
import Whatsapp from "../../../../../assets/svg/socials-icons/WhatsApp.svg?react"
import LocationSpinner from "../../../../../common/ui-kit/spinner"
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import { SubModalMessages } from "../../sub_modal_message"
import s from "./styles.module.scss"

type SendQrProps = {
  onSetEdit: (isEdit: boolean) => void
  userData: UserDataForAddToBaseParticipant
  onSetView: (popupVeiew: ViewsNames) => void
  createdTicket: string
  onCteateTicket: (data: CreateTicketForParticipantData) => void
  ticketSuccess: boolean
  onOpenSubModal: (data: keyof typeof SubModalMessages) => void
  messenger: Messengers | null
  onSetMessanger: (messanger: Messengers) => void
  event_date_day: string
  sendToWhatsApp: SendWhatsAppData
  setSendToWhatsApp: (arg: SendWhatsAppData) => void
  ticketIsLoading: boolean
}

export enum Messengers {
  Telegram = "Telegram",
  Watsapp = "Watsapp",
  Sms = "SMS",
}

const SendQr = ({
  onSetView,
  userData,
  createdTicket,
  onSetEdit,
  ticketSuccess,
  onOpenSubModal,
  messenger,
  onSetMessanger,
  event_date_day,
  onCteateTicket,
  sendToWhatsApp,
  setSendToWhatsApp,
  ticketIsLoading,
}: SendQrProps) => {
  const {
    firstname,
    phone_number,
    lastname,
    telegram_username,
    ticket_url,
    event_id,
    potential_participant_id,
    invited_via_telegram,
    invited_via_whatsapp,
    ticket_id,
  } = userData
  const [
    patchTicket,
    {
      data: newTicket,
      isSuccess: patchSuccess,
      reset: resetPatch,
      isLoading: patchIsLoading,
    },
  ] = usePatchTicketForParticipant()
  const phone_to_send_waatsapp = phone_number.replace(/\D/g, "")
  const isActualEvent = new Date(event_date_day) > new Date()
  const [curTicket, setCurTicket] = useState("")

  useEffect(() => {
    /* отправка в телеграмм */
    if (ticketSuccess && messenger === Messengers.Telegram) {
      sendInMessenger(Object.values(createdTicket)[0])
      onSetView(ViewsNames.SearchUser)
    }
    if (patchSuccess && messenger === Messengers.Telegram) {
      sendInMessenger(Object.values(newTicket)[0])
      resetPatch()
      onSetView(ViewsNames.SearchUser)
    }
    /* открытие модалки "отправить в  Ватсап?"*/
    if (ticketSuccess && messenger === Messengers.Watsapp) {
      sendInMessenger(Object.values(createdTicket)[0] ?? "no")
      setSendToWhatsApp({ ...sendToWhatsApp, isSend: false })
      onSetView(ViewsNames.SearchUser)
    }

    if (patchSuccess && messenger === Messengers.Watsapp) {
      sendInMessenger(Object.values(newTicket)[0] ?? "no")
      setSendToWhatsApp({ ...sendToWhatsApp, isSend: false })
      onSetView(ViewsNames.SearchUser)
      resetPatch()
    }
  }, [ticketSuccess, patchSuccess])

  /* отправка билета в whatsapp по клику на кнопку ДА в субмодалке */
  useEffect(() => {
    if (sendToWhatsApp.isSend) {
      onClickCreateAndSendTicket()
    }
  }, [sendToWhatsApp])

  const onClickBtnGoToView = () => {
    onSetView(ViewsNames.SearchUser)
  }

  const onSubmitVisibleWhatsAppMessenge = () => {
    onOpenSubModal("sendToWhatsApp")
  }

  const onClickOpenSubmodal = (messageType: keyof typeof SubModalMessages) => {
    onOpenSubModal(messageType)
  }

  const openTicketModalWhatsApp = () => {
    onSubmitVisibleWhatsAppMessenge()
  }

  const onClickChooseMessager = (messenger: Messengers) => {
    if (invited_via_telegram && messenger === Messengers.Telegram) {
      onClickOpenSubmodal("wasSend")
      onSetMessanger(messenger)
    } else if (invited_via_whatsapp && messenger === Messengers.Watsapp) {
      onClickOpenSubmodal("wasSend")
      onSetMessanger(messenger)
    } else {
      onSetMessanger(messenger)
    }
  }

  const onSubmitCurrentAction = () => {
    if (!isActualEvent && !invited_via_telegram && !invited_via_whatsapp) {
      onOpenSubModal("createTicket")
      return
    } else if (messenger === Messengers.Watsapp) {
      openTicketModalWhatsApp()
      return
    } else {
      onClickCreateAndSendTicket()
    }
  }

  const onClickCreateAndSendTicket = () => {
    if (ticket_url.length > 1) {
      patchTicket({
        ticket_id: ticket_id,
        invited_via_telegram:
          messenger === Messengers.Telegram
            ? true
            : invited_via_telegram ?? false,
        invited_via_whatsapp:
          messenger === Messengers.Watsapp
            ? true
            : invited_via_whatsapp ?? false,
      })
    } else {
      onCteateTicket({
        event_id: event_id,
        invited_via_telegram:
          messenger === Messengers.Telegram
            ? true
            : invited_via_telegram ?? false,
        invited_via_whatsapp:
          messenger === Messengers.Watsapp
            ? true
            : invited_via_whatsapp ?? false,
        potential_participant_id: potential_participant_id as number,
      })
    }
  }

  const onClickGoToEditUser = () => {
    onSetEdit(true)
    onSetView(ViewsNames.AddNewUser)
  }

  const sendInMessenger = (currentTicket: string) => {
    currentTicket && navigator.clipboard.writeText(currentTicket)
    console.log(currentTicket)
    messenger === Messengers.Telegram
      ? window.open(`https://t.me/
			${telegram_username}?text=${currentTicket}`)
      : window.open(
          `https://wa.me/${phone_to_send_waatsapp}` /* ?text=${currentTicket} */,
        )
  }

  return (
    <div className={s.send_qr}>
      <div>
        <div className={s.user_data}>
          <Avatar
            //src={participant.avatar}
            sx={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
            }}
          >
            <AvatarFallback className={s.user_avatar} />
          </Avatar>
          <h4 className={s.user_name}>{`${firstname} ${lastname}`}</h4>
          <p className={s.user_phone}>{phone_number}</p>
          <button className={s.user_edit} onClick={() => onClickGoToEditUser()}>
            Редактировать
          </button>
        </div>
        <div className={s.socials}>
          <h4 className={s.socials_title}>Выберите куда отправить:</h4>
          <div className={s.socials_icons_block}>
            <div className={s.icon_item}>
              <input
                className={s.socials_icon}
                type="radio"
                id="telegram"
                name="socials"
                checked={messenger === Messengers.Telegram}
              />
              <label
                htmlFor="telegram"
                onClick={() => onClickChooseMessager(Messengers.Telegram)}
              >
                <Telegram
                  className={
                    invited_via_telegram ? s.message_icon_was_sent : ""
                  }
                />
              </label>
            </div>
            <div className={s.icon_item}>
              <input
                className={s.socials_icon}
                type="radio"
                id="whatsapp"
                name="socials"
                checked={messenger === Messengers.Watsapp}
              />
              <label
                htmlFor="whatsapp"
                onClick={() => onClickChooseMessager(Messengers.Watsapp)}
              >
                <Whatsapp
                  className={
                    invited_via_whatsapp ? s.message_icon_was_sent : ""
                  }
                />
              </label>
            </div>
          </div>
          <div className={s.qr_code_wrapper}>
            <QrCode className={s.qr_code} />
          </div>
        </div>
      </div>

      <div className={s.buttons_block}>
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView()}
          style={GradienButtonStyles.borderGradient}
          params={{ text: "Назад", disabled: false }}
        />
        <GradientButton<Object>
          onAction={() => onSubmitCurrentAction()}
          style={GradienButtonStyles.fullGradient}
          params={{
            text: "Отправить QR-код",
            disabled: (!messenger && true) || patchIsLoading || ticketIsLoading,
          }}
        />
      </div>
      {
        /* patchIsLoading || ticketIsLoading && */

        <div className={s.spinner_wrapper}>
          <LocationSpinner />
        </div>
      }
    </div>
  )
}

export default SendQr
