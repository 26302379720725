import { useState } from "react"
import { Link } from "react-router-dom"
import cn from "classnames"
import logo from "../../assets/svg/logo.svg"
import styles from "./styles.module.css"

const ServerError = () => {
  const [isDescriptionHidden, setDescriptionHidden] = useState(true)

  const handleDescriptionClick = () => {
    setDescriptionHidden(!isDescriptionHidden)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles["error-page"]}>
        <span className={styles.logo}>
          <img alt="logo" src={logo} width="124" height="48" />
        </span>

        <h1 className={styles.title}>Ошибка</h1>
        <p className={styles.text}>
          Кажется, что-то пошло не так! Страница, которую вы запрашиваете, не
          существует. Возможно, она устарела, была удалена, или был неверно
          введен адрес в адресной строке
        </p>
        <Link className={styles["main-link"]} to="/">
          На главную
        </Link>
        <button
          className={styles["description-button"]}
          type="button"
          onClick={handleDescriptionClick}
        >
          <span className={styles["description-button__text"]}>
            Описание ошибки
          </span>
          <span
            className={cn(styles["description-button__icon"], {
              [styles["description-button__icon_up"]]: !isDescriptionHidden,
            })}
          ></span>
        </button>
        {!isDescriptionHidden && <p>ERR_CONNECTION_TIMED_OUT</p>}
      </div>
    </div>
  )
}

export default ServerError
