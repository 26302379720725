import { FC } from "react"
import s from "../styles.module.css"
import EmailIcon from "../../../assets/svg/email_icon.svg?react"
import { ResetFormSteps } from "../index"
import {
  useIsEmailExistCheckQuery,
  useSendResetCodeToEmailMutation,
} from "../../../app/data/tribeSwaggerApi"
import GradientBtn from "../../../components/GradientBtn"

type StepProps = {
  back: () => void
  setStep: (step: ResetFormSteps) => void
  email: string
}
const Step1: FC<StepProps> = ({ email, back, setStep }) => {
  const { data, isLoading } = useIsEmailExistCheckQuery({
    email: email,
  })
  const [code] = useSendResetCodeToEmailMutation()

  return (
    <div className={s.step}>
      {!isLoading && !data ? (
        <>
          <p className={s.title_step_1}>Указанный email не существует</p>
          <div className={s.icon}>
            <EmailIcon />
          </div>
        </>
      ) : (
        <>
          <p className={s.title_step_1}>Восстановление пароля</p>

          <div className={s.icon}>
            <EmailIcon />
          </div>

          <div className={s.btn_action}>
            <GradientBtn
              type="submit"
              disabled={!data}
              title="Сбросить пароль"
              aria-label="reset password"
              btnStyle={{
                width: "fit-content",
                height: "36px",
                minWidth: "128px",
                padding: "12px 16px",
                textTransform: "none",
              }}
              handleAction={() => {
                code({
                  resetPasswordDto: {
                    email: email,
                  },
                })
                setStep(2)
              }}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Step1
