export const validatePattern = {
	OnlyLetters: /^[А-Яа-яA-Za-z]+$/,
	TelegramNickname: /^[A-Za-z\d_]{5,32}$/,
}

const validation = 
(inputText: string, patternName: keyof typeof validatePattern): boolean => {
	console.log(/^[A-Za-z\d_]{5,32}$/.test('dsa2eda'))
	if (validatePattern[patternName].test(inputText)) {
		return true
	} else return false
}

export default validation