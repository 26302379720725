import { SxProps, Theme } from "@mui/material"

export const contactsContainer: SxProps<Theme> = {
  height: "100%",
  width: "100%",
  maxHeight: "476px",
  display: "flex",
  flexDirection: "column",
  gap: 0,
  borderRadius: "8px",
  backgroundColor: "var(--inputs-bg)",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "var(--unselected-text) transparent",
  "@media screen and (max-width: 380px)": {
    maxHeight: "420px",
  },
}

export const contactItem: SxProps<Theme> = {
  display: "flex",
  gap: "16px",
  minHeight: "68px",
  height: "auto",
  padding: "8px",
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  border: "1px solid transparent",
  borderRadius: "8px",
  transition: "all 0.2s ease",
  "&:hover": {
    border: "1px solid var(--bg-outside-modal)",
  },
  "@media screen and (max-width: 380px)": {
    minHeight: "84px",
  },
}

export const contactAvatar: SxProps<Theme> = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
}

export const contactInfo: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  columnGap: "8px",
  rowGap: "4px",
  "&::after": {
    content: "''",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: "-8px",
    width: "500px",
    height: "500px",
    backgroundColor: "var(--bg-outside-modal)",
    borderRadius: "50%",
    opacity: 0,
    transition: "opacity 0.5s ease",
  },
  "&:hover::after": {
    opacity: 1,
    transition: "opacity 0.5s ease",
  },
  "@media screen and (max-width: 380px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: 0,
  },
}

export const contactName: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  width: "100%",
  gap: "4px",
  p: {
    overflowWrap: "anywhere",
    color: "var(--secondary-text)",
  },
  "p:first-child": {
    textTransform: "capitalize",
  },
  "@media screen and (max-width: 380px)": {
    rowGap: 0,
  },
}

export const contactDate: SxProps<Theme> = {
  display: "flex",
  overflowWrap: "anywhere",
}
