import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit"
import authorizationSlice from "../features/authorization_page/AuthorizationSlice"
import counterReducer from "../features/counter/counterSlice"
import favoriteSlice from "../pages/favorite/domain/favoriteSlice"
import eventDialogSlice from "../pages/makeEvent/slice/MakeEventSlice"
import signDialogSlice from "../pages/signIn/SignInSlice"
import searchEvents from "../shared/events-feed/eventFeed"
import friendSliceDomain from "../shared/friends-slice/friendsSlice"
import tokenStorage from "./data/appTokensStore"
import { swaggerSplitApi } from "./data/swaggerApi"
import userDataSlice from "./data/userDataSlice/userDataSlice"
import { cityQueryApi } from "./data/location/cityQueryApi"
import popupSlice from "./data/popupSlice/popupSlice"
import  locationSlice from "../shared/location/locationSlice"
import paginationSlice from "../shared/feed-pagination/paginationSlice";
import filterSlice from "../shared/filter/filterSlice";
import categoriesSlice from "../shared/categories"

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    tokenStorage: tokenStorage,
    [swaggerSplitApi.reducerPath]: swaggerSplitApi.reducer,
    loginState: authorizationSlice,
    userProfileState: userDataSlice,
    signDialog: signDialogSlice,
    eventDialog: eventDialogSlice,
    favorites: favoriteSlice,
    friends: friendSliceDomain,
    filter: filterSlice,
    searchEvents: searchEvents,
    popupsState: popupSlice,
    [cityQueryApi.reducerPath]: cityQueryApi.reducer,
    location: locationSlice,
    pagination: paginationSlice,
    categories: categoriesSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      swaggerSplitApi.middleware,
      cityQueryApi.middleware,
    ),
})
