import { FC, PropsWithChildren, useEffect, useRef, useState } from "react"
import useFocusLockModal from "../../hooks/useFocusLockModal"
import Portal from "../portal"
import BtnClose from "../../assets/svg/close.svg?react"
import createContainer from "../portal/createPortal"
import style from "./style.module.css"

const MODAL_CONTAINER_ID = "modal-container-id"

export type ModalProps = PropsWithChildren<{ onClose?: () => void }>
const Modal: FC<ModalProps> = (props: ModalProps) => {
  const [mounted, setMounted] = useState(false)
  const { onClose } = props
  const rootRef = useRef<HTMLDivElement>(null)
  useFocusLockModal({ ref: rootRef, isVisible: mounted })

  useEffect(() => {
    createContainer({ id: MODAL_CONTAINER_ID })
    setMounted(true)

    const handleEscapePress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose?.()
      }
    }

    window.addEventListener("keydown", handleEscapePress)

    return () => {
      // Компонента размонтируется быстрее, чем срабатывает useEffect в хуке useFocusLockModal, из за этого значение в хуке не успевает обновится и скролл обратно не появляется.
      //Поэтому использовал костыль с лайновыми классами при размонтировании компонента.Но в идеале нужно переписать.
      document.body.style.overflow = "auto"
      document.body.style.height = "auto"
      setMounted(false)
      window.removeEventListener("keydown", handleEscapePress)
    }
  }, [onClose])

  return mounted ? (
    <Portal id={MODAL_CONTAINER_ID}>
      <div className={style.wrap} ref={rootRef}>
        <div className={style.body}>
          <BtnClose className={style.btnClose} onClick={() => onClose?.()} />
          <div className={style.guts}>{props.children}</div>
        </div>
      </div>
    </Portal>
  ) : null
}

export default Modal
