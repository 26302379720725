import { SxProps, Theme } from "@mui/material"

export const employeesWrap: SxProps<Theme> = {
  width: "100%",
  paddingBottom: "20px",
}

export const employeeCard: SxProps<Theme> = {
  flex: "0 1 345px",
  display: "flex",
  gap: "20px",
  padding: "14px",
  alignItems: "flex-start",
  justifyContent: "space-between",
  background: "var(--bg-outside-modal)",
  borderRadius: "12px",
  flexWrap: "wrap",

  "@media (min-width: 500px)": {
    flexDirection: "column",
    flex: "0 1 260px",
  },

  "@media (min-width: 1023px)": {
    flex: "0 1 361px",
    flexDirection: "column",
    alignContent: "flex-start",
  },
}

export const employeeCardCont: SxProps<Theme> = {
  display: "flex",
  gap: "20px",
  alignItems: "flex-start",
  justifyContent: "flex-start",
}

export const employeeCardAvatar: SxProps<Theme> = {
  width: "50px !important",
  height: "50px !important",
}

export const employeeCardInfo: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginRight: "10px",
  overflowWrap: "anywhere",
}

export const employeeCardAction: SxProps<Theme> = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  justifyContent: "flex-start",
  width: "100%",
  cursor: "pointer",
  marginLeft: "70px",
}

export const employeesCont: SxProps<Theme> = {
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  justifyContent: "flex-start",
  alignContent: "flex-start",
  overflowY: "auto",
  height: "calc(100vh - 165px)",
  paddingBottom: "100px",
}
