import { styled, Switch, SwitchProps } from "@mui/material"

export const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(() => ({
  width: 38,
  height: 22,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "100ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "var(--white)",
      "& + .MuiSwitch-track": {
        backgroundColor: "var(--filter-range-active)",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 18,
    height: 18,
    color: "var(--main-bg)",
  },
  "& .MuiSwitch-track": {
    borderRadius: 24 / 2,
    backgroundColor: "var(--secondary-text)",
    opacity: 0.8,
  },
}))
