import { Slider } from "@mui/material"
import { FC } from "react"
import s from "../styles.module.css"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";

const countConfig = {
  min: 1,
  max: 36,
  step: 1,
}
const durationTitle = (min: number | undefined, max: number | undefined): string => {
  const minSrt = min === undefined ? countConfig.min: min
  const maxSrt =( max === undefined || max === countConfig.max) ? "∞": max
  return `Продолжительность: от ${minSrt}ч до ${maxSrt}ч`
}
export const FilterDurationBox: FC = () => {
  const {currentFilter, updateDurationRange} = useEventFilter()
  const [min, max] = [currentFilter.durationEventInHoursMin, currentFilter.durationEventInHoursMax]
  const onChange = (_: Event, v: number | number[], active: number) => {
    if (Array.isArray(v)) {

      const [newMin, newMax] = v

      const minArg = (newMin === countConfig.min) ? undefined : newMin
      const maxArg = (newMax === countConfig.max) ? undefined : newMax

      updateDurationRange([minArg, maxArg])
    }
  }

  return (
    <div className={s.category_box}>
      <h4 className={s.title}>
        {durationTitle(min, max)}
      </h4>

      <div className={s.box}>
        <Slider
          min={countConfig.min}
          max={countConfig.max}
          step={countConfig.step}
          marks
          sx={{
            color: "var(--filter-range-active)",
            marginLeft: "10.5px",
            width: `calc(100% - 21px)`,
            padding: "16px 0",
          }}
          getAriaLabel={() => "Minimum duration"}
          value={[min ?? countConfig.min, max ?? countConfig.max]}
          onChange={onChange}
          valueLabelDisplay="auto"
          disableSwap
        />
      </div>
    </div>
  )
}
