import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { selectUserId, userLogout } from "../../../app/data/appTokensStore"
import { useDeleteUserProfileMutation } from "../../../app/data/userDataSlice/userApi"
import { IoError, ioErrorOf } from "../../../app/data/errors"
import { logout } from "../../../features/authorization_page/AuthorizationSlice"

export const useDeleteUser = (): {
  deleteUser: () => void
  isLoading: boolean
  error?: IoError
} => {
  const dispatch = useAppDispatch()
  const userId = useAppSelector(selectUserId)

  const [deleteUserProfile, { isLoading, error }] =
    useDeleteUserProfileMutation()

  const deleteUser = () => {
    if (!userId) return

    if (window.confirm("Вы уверены, что хотите удалить пользователя?")) {
      deleteUserProfile({ userId }).then(() => {
        dispatch(logout())
        dispatch(userLogout())
      })
    }
  }

  return { deleteUser, isLoading, error: ioErrorOf(error) }
}
