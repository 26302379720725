import React, { FC, useEffect, useState } from "react";
import { Box } from "@mui/material"
import { CategoryInfo } from "../../filtered/types"
import { categoryTab } from "../categoryTabsStyles"
import style from "../styles.module.css"
import { useAppSelector } from "../../../app/hooks";
import { selectCurrentTypeIds } from "../../../shared/filter/selectors";

export type CategoryItemProps = {
  category: CategoryInfo
  click: (value: number) => void
}
const filterStyle = (isSelected: boolean):  React.CSSProperties => {
  return  {
    filter: `${isSelected? "none" : "grayscale(100%)"}`,
  }
}

const CategoryItem: FC<CategoryItemProps> = (props) => {
  const { category: cat } = props
  const currentIds = useAppSelector(selectCurrentTypeIds)
  const [isSelected, setIsSelected] = useState<boolean>(true)

  const clickHandler = () => {
    props.click(cat.id)
  }
  useEffect(() => {
    if (currentIds===undefined || currentIds.includes(cat.id)) {
      setIsSelected(true)
    }else  {
      setIsSelected(false)
    }
  }, [currentIds]);
  return (
    <Box
      onClick={clickHandler}
      sx={categoryTab}
      style={filterStyle(isSelected)}
    >
      <picture>
        <img
          className={style.category_card}
          src={cat.url}
          srcSet={cat.srcset}
          alt={cat.title}
        />
      </picture>
    </Box>
  )
}
export default CategoryItem
