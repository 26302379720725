import classNames from "classnames"
import s from "./styles.module.scss"

export enum GradienButtonStyles {
  borderGradient = "border",
  fullGradient = "full",
}

type GradientButtonProps<yourType> = {
  style: GradienButtonStyles
  fetchIsLoading?: boolean
  onAction: (props?: yourType) => void
  params: {
    width?: number
    text: string
    height?: number
    disabled?: boolean
  }
}

function GradientButton<yourType>({
  onAction,
  style,
  params,
  fetchIsLoading,
}: GradientButtonProps<yourType>) {
  const { text, height, width, disabled } = params

  const handleCurrentAction = (e?: yourType) => {
    e ? onAction(e) : onAction()
  }

  return (
    <>
      {style === GradienButtonStyles.borderGradient ? (
        <button
          onClick={() => handleCurrentAction()}
          className={classNames(
            s.button,
            s.button_border,
            fetchIsLoading && s.button_disabled,
            disabled && s.button_disabled,
          )}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <p className={`${s.button_text} ${s.button_text_gradient}`}>{text}</p>
        </button>
      ) : (
        <button
          onClick={() => handleCurrentAction()}
          className={classNames(
            s.button,
            s.button_full,
            fetchIsLoading && s.button_disabled,
            disabled && s.button_disabled,
          )}
          style={{
            width: `${width}px`,
            height: `${height}px`,
          }}
        >
          <p className={s.button_text}>{text}</p>
        </button>
      )}
    </>
  )
}

export default GradientButton
