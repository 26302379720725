import { SxProps, Theme } from "@mui/material"

export const card: SxProps<Theme> = {
  flex: "0 1 350px",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  p: 2,
  background: "var(--dialog-bg)",
  border: "1px solid transparent",
  borderRadius: "12px",
}

export const avatar: SxProps<Theme> = { width: "50px", height: "50px" }

export const userInfoCont: SxProps<Theme> = { display: "flex", gap: "10px" }

export const btnInfoCont: SxProps<Theme> = {
  display: "flex",
  gap: "20px",
  flexDirection: "column",
  border: "1px solid var(--borders)",
  borderRadius: "16px",
  padding: "10px",
  transition: "all 0.3s ease",
  cursor: "pointer",

  "&:hover": {
    borderColor: "var(--filter-range-active)",
    background: "var(--filter-selected)",
  },
}

export const btnCont: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "5px",
  overflowWrap: "anywhere",
}

export const btnTitle: SxProps<Theme> = {
  fontSize: "16px",
  fontWeight: "500px",
  color: "var(--primary-text)",
}

export const btnInfo: SxProps<Theme> = {
  fontSize: "24px",
  fontWeight: "400px",
  color: "var(--primary-text)",
}

export const btnInfo2: SxProps<Theme> = {
  fontSize: "12px",
  fontWeight: 300,
  color: "var(--primary-text)",
}

export const deleteUser: SxProps<Theme> = {
  display: "flex",
  gap: "10px",
  alignItems: "center",
  width: "max-content",
  padding: "10px",
  border: "1px solid transparent",
  borderRadius: "16px",
  transition: "all 0.3s ease",
  cursor: "pointer",

  "&:hover": {
    borderColor: "var(--filter-range-active)",
    background: "var(--filter-selected)",
  },
}

export const deleteUserText: SxProps<Theme> = {
  fontSize: "14px",
  fontWeight: 400,
  color: "var(--filter-range-active)",
  textTransform: "uppercase",
}

export const rateStyle = {
  display: "flex",
  gap: "20px",
  flexDirection: "column",
  border: "1px solid var(--borders)",
  borderRadius: "16px",
  padding: "10px",
}
