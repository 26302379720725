import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"
import { CSSProperties } from "react"

export const card: SxProps<Theme> = {
  display: "grid",
  gridAutoFlow: "row",
  backgroundColor: "var(--surface-bg)",
  fontFamily: "Roboto, sans-serif",
  color: "var(--secondary-text)",
  padding: "8px",
  gap: "8px",
  borderRadius: "16px",
  gridTemplate: "auto 1fr minmax(36px, auto) / 1fr",
  boxSizing: "border-box",
  overflowX: "hidden",
}

export const cardImg: SxProps<Theme> = {
  position: "relative",
  padding: "0px 0px 51% 0px",
  borderRadius: "8px",
  backgroundColor: "var(--borders)",
  img: {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}

export const cardContent: SxProps<Theme> = {
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  borderBottom: "1px solid var(--borders)",
  paddingBottom: "8px",
}

export const cardQrButton: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "160px",
  minHeight: "36px",
  padding: "10px 8px",
  background: "var(--btn-gradient)",
  border: "none",
  borderRadius: "16px",
  position: "absolute",
  top: "50%",
  right: 0,
  transform: "translate(-1.5px, -50%)",
  "&::after": {
    content: "''",
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(-1.5px, -50%)",
    width: "calc(100% - 3px)",
    height: "calc(100% - 3px)",
    background: "var(--surface-bg)",
    borderRadius: "16px",
    zIndex: -1,
  },
}

export const cardQrButtonText: CSSProperties = {
  backgroundImage: "var(--btn-gradient)",
  backgroundClip: "text",
  color: "transparent",
  fontFamily: "Manrope, sans-serif",
  fontSize: "12px",
  fontWeight: "400",
  textTransform: "uppercase",
  lineHeight: "16px",
  letterSpacing: "0.4px",
}

export const cardVerifText: CSSProperties = {
  backgroundImage: "var(--btn-gradient)",
  backgroundClip: "text",
  color: "transparent",
  fontFamily: "Manrope, sans-serif",
  fontSize: "16px",
  fontWeight: "400",
  textTransform: "uppercase",
  lineHeight: "16px",
  letterSpacing: "0.4px",
}
