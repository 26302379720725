import { Avatar } from "@mui/material"
import { MutableRefObject, useEffect, useState } from "react"
import { IMaskInput } from "react-imask"
import { ViewsNames } from "../.."
import {
  PotentionalParticipantById,
  useLazyGetAllParticipantsQuery,
  UserDataForAddToBaseParticipant,
} from "../../../../../app/data/promotion/promotionApi"
import AvatarFallback from "../../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import SearchIcon from "../../../../../assets/svg/menu-icons-pack/search-icon.svg?react"
import Ticket from "../../../../../assets/svg/profile/Ticket_use.svg?react"
import LocationSpinner from "../../../../../common/ui-kit/spinner"
import { setPage } from "../../../../../shared/feed-pagination/paginationSlice"
import GradientBtn from "../../../../GradientBtn"
import { SubModalMessages } from "../../sub_modal_message"
import s from "./styles.module.scss"

type SearchUserProps = {
  onSetVeiew: (popupVeiew: ViewsNames) => void
  eventId: number
  onSetCurrentUser: (data: UserDataForAddToBaseParticipant) => void
  onClicksetBackView: (view: ViewsNames) => void
  onSetPage: (page: number) => void
  currePage: number
  participantUlList: MutableRefObject<HTMLUListElement | null>
  scrolled: number
  setScrolled: (scrollTop: number) => void
  onOpenSubmodal: (message: keyof typeof SubModalMessages) => void
}

const SearchUser = ({
  onSetVeiew,
  eventId,
  onSetCurrentUser,
  onClicksetBackView,
  onSetPage,
  currePage,
  participantUlList,
  scrolled,
  setScrolled,
  onOpenSubmodal,
}: SearchUserProps) => {
  const [searchedValue, setSearchedValue] = useState<string>("")
  const currentMask = `{7}(000)000-00-00`
  const [onGetParticipants, { data, isFetching, isLoading }] =
    useLazyGetAllParticipantsQuery()
  const participants = data?.content ?? []
  const [wasInside, setWasInside] = useState(false)

  useEffect(() => {
    const searchNumberForRes =
      searchedValue.length > 0 ? searchedValue : undefined
    if (searchedValue.length > 0) {
      onGetParticipants({
        event_id: eventId,
        page: currePage,
        partial_phone_number: searchNumberForRes,
      })
    } else {
      onGetParticipants({
        event_id: eventId,
        page: currePage,
        partial_phone_number: "№",
      })
    }
  }, [searchedValue, currePage])

  useEffect(() => {
    const onScroll = () => {
      const list = participantUlList?.current
      if (scrolled < (list?.scrollTop as number)) {
        setScrolled(list?.scrollTop as number)
      }

      const scrolledToBottom =
        (list?.scrollTop as number) >=
        (list?.scrollHeight as number) -
          ((list?.scrollHeight as number) / 100) * 31

      if (list?.scrollTop === 0) {
        console.log("Начальная позиция")
        onSetPage(0)
      }
      if (currePage === data?.totalPages) {
        return
      }
      if (scrolledToBottom && !isFetching) {
        console.log("Fetching more data...")
        onSetPage(currePage + 1)
      }
    }
    participantUlList.current?.addEventListener("scroll", onScroll)
    return function () {
      participantUlList.current?.removeEventListener("scroll", onScroll)
    }
  }, [currePage, isFetching])

  const onBackspaceClearValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && e.currentTarget.value.length === 0) {
      console.log(e.currentTarget.value)
      setSearchedValue("")
    }
  }

  const onChangeSearchedValue = (e: React.FormEvent<HTMLInputElement>) => {
    onClickScrollTop()
    setPage(0)
    const value = e.currentTarget.value
    setSearchedValue(value)
  }

  const hadleGoToAddNewUser = () => {
    onSetVeiew(ViewsNames.AddNewUser)
  }

  useEffect(() => {
    console.log(isFetching, "загрузка участников")
  }, [isFetching])

  const onClickScrollTop = () => {
    setWasInside(true)
    participantUlList.current?.scroll({
      top: 0,
      behavior: "instant",
    })
  }

  const onClickSetCurrentUser = (currentUser: PotentionalParticipantById) => {
    console.log(currentUser)
    const forSet = {
      event_id: eventId,
      lastname: currentUser?.lastname ?? "",
      firstname: currentUser?.firstname ?? "",
      phone_number: currentUser?.phone_number ?? "",
      telegram_username: currentUser?.telegram_username ?? "",
      ticket_id: currentUser?.ticket_id ?? 0,
      ticket_url: currentUser?.ticket_url ?? "",
      potential_participant_id: currentUser.potential_participant_id,
      invited_via_whatsapp: currentUser.invited_via_whatsapp,
      invited_via_telegram: currentUser.invited_via_telegram,
    }
    onSetCurrentUser(forSet)
    onClicksetBackView(ViewsNames.SearchUser)
  }

  const transformLength = (str: string, maxLength: number = 20) =>
    str.length > maxLength ? `${str.slice(0, maxLength)}...` : str

  const renderCantEditModal = () => {
    onOpenSubmodal("cantEdit")
  }

  const renderParticipantsItems = () =>
    participants?.map((participant) => (
      <li
        className={s.user_item}
        key={participant.phone_number}
        onClick={() =>
          participant.can_update_info
            ? onClickSetCurrentUser(participant)
            : renderCantEditModal()
        }
        tabIndex={0}
      >
        <Avatar
          //src={participant.avatar}
          sx={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
          }}
        >
          <AvatarFallback className={s.user_avatar} />
        </Avatar>
        <div className={s.user_info}>
          <h3 className={s.user_name}>
            {transformLength(
              `${participant.firstname} ${participant.lastname}`,
              17,
            )}
          </h3>
          <p className={s.user_phone}>{participant.phone_number}</p>
        </div>

        {participant.ticket_url && (
          <div className={s.user_info}>
            <Ticket />
          </div>
        )}
      </li>
    ))

  return (
    <div className={s.add_to_base_container}>
      <div className={s.add_to_base}>
        <div className={s.add_to_base_search}>
          <label className={s.search_label} htmlFor="search">
            <SearchIcon />
          </label>
          <IMaskInput
            onClick={() => onClickScrollTop()}
            mask={currentMask}
            onKeyDown={(e) => onBackspaceClearValue(e)}
            onChange={(e) => onChangeSearchedValue(e)}
            autoComplete="off"
            className={s.search_input}
            value={searchedValue}
            type="search"
            id="search"
            placeholder="Поиск участников"
            pattern="[A-Za-z]{3}"
          />
        </div>
        <ul
          className={s.add_to_base_list}
          ref={participantUlList}
          style={{
            overflowY: isFetching ? "hidden" : "auto",
            pointerEvents: isFetching ? "none" : "auto",
          }}
        >
          {!wasInside
            ? "Введите номер телефона"
            : data?.content.length === 0
            ? "В базе нет ни одного пользователя"
            : renderParticipantsItems()}
        </ul>
      </div>
      <div className={s.buttons_block}>
        <GradientBtn
          title="Добавить нового человека в базу"
          handleAction={() => hadleGoToAddNewUser()}
          btnStyle={{
            height: "36px",
          }}
          textStyle={{
            fontFamily: "Manrope, sans-serif",
            fontSize: "12px",
            lineHeight: "16px",
            fontWeight: 400,
            letterSpacing: "0.4px",
          }}
        />
      </div>
      {data?.totalPages !== data?.number && (isLoading || isFetching) && (
        <div className={s.spinner_wrapper}>
          <LocationSpinner />
        </div>
      )}
    </div>
  )
}

export default SearchUser
