import { useEffect } from "react"
import { useForm, SubmitHandler } from "react-hook-form"
import { Avatar, Button } from "@mui/material"

import { useProfileSettingEditor } from "../hooks/useProfileSetting"
import { useDeleteUser } from "../hooks/useDeleteUser"
import ClearInputIcon from "../../../assets/svg/menu-icons-pack/close-round-icon-menu.svg?react"
import AvatarFallback from "../../../assets/svg/menu-icons-pack/avatar.svg?react"
import { userAvatar, avatarUploadBtn } from "./profileAndViewStyle"
import LocationSpinner from "../../../common/ui-kit/spinner"
import PhotoIcon from "../../../assets/svg/photo.svg?react"
import s from "./styles.module.scss"

export interface FormValues {
  username: string
  firstName?: string
  lastName?: string
  birthday?: string
}

const ProfileAndViewSettings = (): JSX.Element => {
  const { register, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      username: "",
      firstName: "",
      lastName: "",
      birthday: "",
    },
  })

  const {
    updateProfile,
    isLoading,
    error,
    currentUser: state,
  } = useProfileSettingEditor()

  const { deleteUser } = useDeleteUser()

  useEffect(() => {
    if (state) {
      reset({
        username: state.username,
        firstName: state.first_name,
        lastName: state.last_name,
        birthday: state.birthday,
      })
    }
  }, [state, reset])

  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

  const today = new Date()
  const maxBirthday = new Date(today.setFullYear(today.getFullYear() - 12))
    .toISOString()
    .slice(0, 10) //Пользователь старше 12 лет
  const minBirthday = new Date(today.setFullYear(today.getFullYear() - 120))
    .toISOString()
    .slice(0, 10) //Пользователь не старше 120

  const onSubmit: SubmitHandler<FormValues> = ({
    username,
    firstName,
    lastName,
    birthday,
  }): void => {
    updateProfile({
      username,
      first_name: firstName ? capitalize(firstName) : undefined,
      last_name: lastName ? capitalize(lastName) : undefined,
      birthday,
    })
  }

  if (isLoading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "auto",
        }}
      >
        <LocationSpinner />
      </div>
    )
  if (error) return <div>{error.error}</div>

  return (
    <div className={s.user}>
      <div className={s.user_avatar}>
        <Avatar src={state?.avatar_url} sx={userAvatar}>
          <AvatarFallback className={s.user_avatar_fallback} />
        </Avatar>
      </div>
      <div className={s.user_username}>{`@${state?.username}`}</div>
      <div className={s.user_fullname}>
        {state?.first_name && state?.last_name
          ? `${state.first_name} ${state.last_name}`
          : ""}
      </div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        name="profileSettingsForm"
        className={s.form}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
      >
        <div className={s.form__item}>
          <input
            placeholder="Логин"
            className={s.form__input}
            spellCheck="false"
            autoComplete="off"
            maxLength={50}
            required={!!state?.username}
            {...register("username")}
          />
          <button
            className={s.form__input_clear}
            tabIndex={-1}
            aria-label="Очистить"
            onClick={() => {
              reset({ username: "" })
            }}
          >
            <ClearInputIcon />
          </button>
        </div>
        <div className={s.form__item}>
          <input
            placeholder="Имя"
            className={s.form__input}
            maxLength={50}
            autoComplete="off"
            required={!!state?.first_name}
            {...register("firstName")}
          />
          <button
            className={s.form__input_clear}
            tabIndex={-1}
            aria-label="Очистить"
            onClick={() => {
              reset({ firstName: "" })
            }}
          >
            <ClearInputIcon />
          </button>
        </div>
        <div className={s.form__item}>
          <input
            placeholder="Фамилия"
            className={s.form__input}
            maxLength={50}
            autoComplete="off"
            required={!!state?.last_name}
            {...register("lastName")}
          />
          <button
            className={s.form__input_clear}
            tabIndex={-1}
            aria-label="Очистить"
            onClick={() => {
              reset({ lastName: "" })
            }}
          >
            <ClearInputIcon />
          </button>
        </div>
        <div className={s.form__item}>
          <input
            type="date"
            min={minBirthday}
            max={maxBirthday}
            className={s.form__input}
            maxLength={10}
            autoComplete="off"
            required={!!state?.birthday}
            {...register("birthday")}
          />
          <button
            className={s.form__input_clear}
            tabIndex={-1}
            aria-label="Очистить"
            onClick={() => {
              reset({ birthday: "" })
            }}
          >
            <ClearInputIcon />
          </button>
        </div>
        <button className={s.form__btn_save} aria-label="Сохранить">
          Сохранить изменения
        </button>
      </form>
      <button
        className={s.user__delete}
        aria-label="Удалить аккаунт"
        onClick={() => deleteUser()}
      >
        удалить аккаунт
      </button>
    </div>
  )
}
export default ProfileAndViewSettings
