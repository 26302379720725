import React, { FC, useState } from "react"
import { useValidateFormikData } from "../../../hooks/useValidateFormikData"
import { TStepProps } from "../../../types"
import Buttons from "../../common/buttons"
import Title from "../../common/title"
import s from "./styles.module.css"
import { descriptionSchema } from "./schema"

const Step6: FC<TStepProps> = ({ makeStep, step, formik }) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = () => setIsFocused(false)

  const { description } = formik.values

  const isFieldEmpty = useValidateFormikData(descriptionSchema, description)

  return (
    <div className={s.container}>
      <Title align={"center"} title={"Придумайте описание"} />
      <div className={s.content}>
        <p className={s.text_info}>Расскажите о вашем мероприятии подробней</p>
        <textarea
          className={s.textarea}
          name={"description"}
          value={description}
          onChange={formik.handleChange}
          placeholder={"Описание..."}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
        <p className={s.text_info}>
          Используйте слова, которые описывают тематику сообщества и помогают
          быстрее его найти.{" "}
        </p>
      </div>
      <Buttons
        disabled={isFieldEmpty}
        step={step}
        next={() => {
          if (isFieldEmpty) return
          makeStep("+")
        }}
        back={() => makeStep("-")}
      />
    </div>
  )
}

export default Step6
