import { FC, useEffect, useState } from "react"
import s from "../styles.module.css"
import EmailIcon from "../../../assets/svg/email_icon.svg?react"
import CodeInputs from "../../../common/codeInputs"
import { ResetFormSteps } from "../index"
import { useConfirmResetCodeMutation } from "../../../app/data/tribeSwaggerApi"
import { useAppDispatch } from "../../../app/hooks"
import { setUserCredentials } from "../../../app/data/appTokensStore"

type StepProps = {
  login: string
  setStep: (step: ResetFormSteps) => void
  onCode: (code: number) => void
}
const Step2: FC<StepProps> = ({ onCode, login, setStep }) => {
  const [codeValue, setCodeValue] = useState<null | string>(null)
  const [confirmCode, { isError }] = useConfirmResetCodeMutation()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (codeValue) {
      const code = Number.parseInt(codeValue)
      confirmCode({
        emailConfirmCodeDto: {
          email: login,
          verification_code: code,
        },
      })
        .unwrap()
        .then((value) => {
          dispatch(
            setUserCredentials({
              applicationToken: value.access_token,
              refreshToken: value.refresh_token,
              userId: value.user_id,
            }),
          )
          onCode(code)
          setStep(3)
        })
    }
  }, [codeValue])
  return (
    <div className={s.step}>
      <div className={s.icon}>
        <EmailIcon />
      </div>
      <p className={s.title_step_2}>{`На адрес ${login} был выслан
        четырёхзначный пароль введите его ниже:`}</p>
      <CodeInputs
        handleChange={(code) => setCodeValue(code)}
        errorText={isError ? "Неверно введен код" : null}
        clearError={() => {}}
      />
    </div>
  )
}

export default Step2
