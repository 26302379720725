import { z } from "zod";

const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/;

export const CommonEventSchema = z.object({
  event_name: z.string().min(5),
  is_private: z.boolean(),
  start_time: z.string().regex(regex),
  end_time: z.string().regex(regex),
  event_type_id: z.number(),
})

export const EventsTypeSchema = z.object({
  id: z.number(),
  type_name: z.string()
})