import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { EventDialogFormats } from "../../../components/popups/EventDialog"
import { EventModel } from "../../../types"
import { EventInfoProfile } from "../../../shared/user-profile/types"

type PopupStore = {
  eventDialogFormat: EventDialogFormats | null
  eventData: EventModel | null
  promotionPopup: Pick<EventInfoProfile, 'event_name' | 'id' | 'start_time'> | null
}

const initialState: PopupStore = {
  eventDialogFormat: null,
  eventData: null,
  promotionPopup: null
}

const popupSlice = createSlice({
  name: "POPUP-DATA",
  initialState,
  reducers: {
    setEventDialogFormat: (
      state,
      action: PayloadAction<EventDialogFormats>,
    ) => {
      state.eventDialogFormat = action.payload
    },
    setEventData: (state, action: PayloadAction<EventModel | null>) => {
      state.eventData = action.payload
    },
    setPromotionPopup: (
      state, action: PayloadAction<Pick<EventInfoProfile, 'event_name' | 'id' | 'start_time'> | null>) => {
        state.promotionPopup = action.payload
      }
  },
})

export default popupSlice.reducer

export const { setEventDialogFormat, setEventData, setPromotionPopup } = popupSlice.actions
