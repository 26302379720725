import { FC } from "react"
import { Link } from "react-router-dom"
import {
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Button,
} from "@mui/material"
import { EventInfoProfile } from "../../../shared/user-profile/types"
import {
  card,
  cardContent,
  cardImg,
  cardQrButton,
  cardQrButtonText,
  cardVerifText,
} from "./styles"
import s from "./styles.module.css"
import FallbackImg from "../../../assets/svg/profile/logo-icon.svg"
import { DateIcon } from "../../../assets/svg/profile/DateIcon"
import { LocationIcon } from "../../../assets/svg/profile/LocationIcon"
import { TimeIcon } from "../../../assets/svg/profile/TimeIcon"
import { useAppDispatch } from "../../../app/hooks"
import { setPromotionPopup } from "../../../app/data/popupSlice/popupSlice"

export type ProfileEventCardProps = {
  info: EventInfoProfile
  //qrCodePush?: (id: number) => void
  isPromotion?: boolean
}
const ProfileEventCard: FC<ProfileEventCardProps> = ({
  info,
  //qrCodePush,
  isPromotion,
}) => {
  const dispatch = useAppDispatch()
  //const qrButtonPush = () => {
  //  if (qrCodePush) {
  //    qrCodePush(info.id)
  //  }
  //}

  const handleSetPromotionPopup = () => {
    const dataForPopup = { id: info.id, event_name: info.event_name, start_time: info.start_time }
    dispatch(setPromotionPopup(dataForPopup))
    lockBodyScroll()
  }

  const lockBodyScroll = () => {
    document.body.style.overflow = "hidden"
    document.body.style.height = "100vh"
  }

  const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)
  const eventDatetime = Date.parse(info.start_time)
  const dateFormatter = new Intl.DateTimeFormat("ru", {
    year: "numeric",
    month: "long",
    day: "numeric",
  })
  const timeFormatter = new Intl.DateTimeFormat("ru", {
    hour: "numeric",
    minute: "numeric",
  })

  return (
    <Card
      sx={card}
      style={{ opacity: info.status === "VERIFICATION_PENDING" ? 0.7 : 1 }}
    >
      <CardMedia
        sx={cardImg}
        style={{
          filter:
            info.status === "VERIFICATION_PENDING"
              ? "grayscale(1)"
              : "grayscale(0)",
        }}
        image={info.event_photo_url || FallbackImg}
        title={info.event_name}
        component={info.status === "VERIFICATION_PENDING" ? "div" : Link}
        to={
          info.status === "VERIFICATION_PENDING" ? undefined : `/fid/${info.id}`
        }
      />
      <CardContent sx={cardContent}>
        <h2 className={s.cardTitle}>
          {info.status === "VERIFICATION_PENDING" ? (
            capitalize(info.event_name)
          ) : (
            <Link to={`/fid/${info.id}`}>{capitalize(info.event_name)}</Link>
          )}
        </h2>
        <ul className={s.infoItems}>
          <li className={s.infoItem}>
            <LocationIcon className={s.infoIcon} />
            {info.city}
          </li>
          <li className={s.infoItem}>
            <DateIcon className={s.infoIcon} />
            {dateFormatter.format(eventDatetime)}
          </li>
          <li className={s.infoItem}>
            <TimeIcon className={s.infoIcon} />
            {timeFormatter.format(eventDatetime)}
          </li>
        </ul>
      </CardContent>
      <CardActions
        sx={{
          padding: 0,
          justifyContent: "center",
          position: "relative",
          width: "100%",
        }}
      >
        {isPromotion ? (
          <Button
            sx={cardQrButton}
            onClick={handleSetPromotionPopup}
            variant="contained"
          >
            <p style={cardQrButtonText}>Выслать Qr-код</p>
          </Button>
        ) : (
          <>
            {info.status === "PUBLISHED" ? (
              <Button sx={cardQrButton} variant="contained">
                <Link to={`/fid/${info.id}`}>
                  <p style={cardQrButtonText}>Подробнее</p>
                </Link>
              </Button>
            ) : (
              <div style={cardVerifText}>Событие на проверке</div>
            )}
          </>
        )}
      </CardActions>
    </Card>
  )
}

export default ProfileEventCard
