import React, {
  FC,
  FormEvent,
  FormEventHandler,
  useEffect,
  useState,
} from "react"
import style from "./styles.module.css"
import InputPassword from "../../common/ui-kit/inputs/input_password"
import GradientBtn from "../../components/GradientBtn"
import { useFormik } from "formik"
import Code from "./code"
import useRegistrationFetch from "./useRegistrationFetch"
import { useAppDispatch } from "../../app/hooks"
import { hide } from "../../pages/signIn/SignInSlice"
import { isCheckUsername } from "../../app/common/validationFetch"
import {
  validEmailForRegistration,
  validLoginForRegistration,
} from "../../app/common/validations"
import { validateForm } from "./FormValidation"

export interface RegisterFormValues {
  userName: string
  email: string
  password: string
  rePassword: string
}

const RegistrationForm: FC<RegisterFormValues> = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const { tryRegistration, registrantId, isError } = useRegistrationFetch()
  const dispatch = useAppDispatch()
  const formik = useFormik<RegisterFormValues>({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      rePassword: "",
    },
    validate: validateForm,
    onSubmit: (values) => {
      tryRegistration(values)
      setIsSuccess(true)
    },
  })
  const handleCodeFetch = () => {
    dispatch(hide())
  }
  if (isSuccess && registrantId) {
    return (
      <Code
        email={formik.values.email}
        handleSubmit={handleCodeFetch}
        registrantId={registrantId}
      />
    )
  }

  const isFormValid =
    formik.values.userName &&
    formik.values.email &&
    formik.values.password &&
    formik.values.rePassword &&
    formik.values.password === formik.values.rePassword

  return (
    <form className={style.form_box}>
      <div className={style.input_container}>
        <InputPassword
          errorText={
            formik.touched.userName && formik.errors.userName
              ? formik.errors.userName
              : null
          }
          name={"userName"}
          value={formik.values.userName}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"text"}
          placeholder={"@username"}
          autocomplete={"off"}
        />
        <InputPassword
          errorText={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
          name={"email"}
          value={formik.values.email}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"text"}
          placeholder={"email"}
        />
        <InputPassword
          errorText={
            formik.touched.password && formik.errors.password
              ? formik.errors.password
              : null
          }
          name={"password"}
          value={formik.values.password}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"password"}
          placeholder={"password"}
        />
        <InputPassword
          errorText={
            formik.touched.password && formik.errors.rePassword
              ? formik.errors.rePassword
              : null
          }
          name={"rePassword"}
          value={formik.values.rePassword}
          onBlur={formik.handleBlur}
          handleChange={formik.handleChange}
          type={"password"}
          placeholder={"password"}
        />
      </div>
      {isError ? <p className={style.error_text}>Ошибка сервера</p> : null}
      <GradientBtn
        type="submit"
        disabled={!isFormValid}
        title="Регистрация"
        aria-label="signup"
        btnStyle={{
          width: "fit-content",
          minWidth: "128px",
          padding: "12px 16px",
          marginTop: "16px",
          ...(!isFormValid && {
            background: "var(--btns-disabled-bg)",
            color: "var(--btns-disabled-text)",
            pointerEvents: "none",
          }),
        }}
        handleAction={formik.handleSubmit}
      />
    </form>
  )
}
export default RegistrationForm
