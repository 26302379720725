import eventPhoto, { avatarUrlDecorator } from "../avatarUrl"
import { swaggerSplitApi as api } from "../swaggerApi"
import { mapProfileEventDtoToModel } from "../../../components/feed_page/mapper"
import { setTribrePromotion } from "../userDataSlice/userDataSlice"
import { current } from "@reduxjs/toolkit"

export type CashierOrPromoterEventsRole = {
  totalPages: number
  totalElements: number
  size: number
  content: [
    {
      id: 0
      name: string
      city: string
      start_date_time: string
      image_url: string
      event_type_id: number
      is_finished: boolean
      is_promoter: boolean
      is_cashier: boolean
      event_status: string
    },
  ]
  number: number
  sort: [
    {
      direction: string
      nullHandling: string
      ascending: boolean
      property: string
      ignoreCase: boolean
    },
  ]
  pageable: {
    offset: number
    sort: [
      {
        direction: string
        nullHandling: string
        ascending: boolean
        property: string
        ignoreCase: boolean
      },
    ]
    paged: boolean
    pageNumber: number
    pageSize: number
    unpaged: boolean
  }
  numberOfElements: number
  first: boolean
  last: boolean
  empty: boolean
}

export type EmployeesData = {
  content: Employees[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
    sort: { empty: boolean; sorted: boolean; unsorted: boolean }
  }
  size: number
  sort: { empty: boolean; sorted: boolean; unsorted: boolean }
  totalElements: number
  totalPages: number
}

export type EmployeesDataExtended = EmployeesData & {
  promotersIds: number[]
}

export type PromoutersParams = Omit<InvitedParam, "promoterId" | "phone">
export type EventStatistic = {
  id: number
  name: string
  img_url: string
  start_at: string
  invited_count: number
  participants_count: number
  promoters_count: number
  cashiers_count: number
}
export type Employees = {
  id: number
  firstname: string
  lastname: string
  avatar_url: string
  user_id: number
  username: string
}

export type PromoCard = {
  cashiers_count: number
  id: number
  img_url: string
  invited_count: number
  name: string
  participants_count: number
  promoters_count: number
  start_at: string
}

export type CashiersData = {
  cashier_id: number
  firstname: string
  lastname: string
  phone_number: string
  avatar_url: string
  participant_count: number
}

export type CreateTicketForParticipantData = {
  potential_participant_id: number
  invited_via_telegram: boolean
  invited_via_whatsapp: boolean
  event_id: number
}

export type PatchTicketForParticipantData = Pick<
  CreateTicketForParticipantData,
  "invited_via_telegram" | "invited_via_whatsapp"
> & {
  ticket_id: number
}

export type InvitedParam = {
  eventId: number
  promoterId?: number
  phone?: string
  page?: number
  size?: number
}

export type ParticipantsParam = InvitedParam & { cashierId?: number }

export type InvitedUser = {
  id: number
  firstname: string
  lastname: string
  invited: string
  activated: string
  phone_number: string
}

export type UserDataForAddToBaseParticipant = {
  potential_participant_id?: number
  event_id: number
  lastname: string
  firstname: string
  phone_number: string
  telegram_username: string
  ticket_url: string
  ticket_id: number
  invited_via_telegram: boolean
  invited_via_whatsapp: boolean
}

export type UserDataForPatchToBaseParticipant = Omit<
  UserDataForAddToBaseParticipant,
  | "event_id"
  | "ticket_id"
  | "ticket_url"
  | "invited_via_telegram"
  | "invited_via_whatsapp"
> & {}

export type PotentionalParticipantById = {
  potential_participant_id: number
  firstname: string
  lastname: string
  phone_number: string
  invited_via_telegram: true
  telegram_username: string
  invited_via_whatsapp: true
  ticket_url: string
  can_update_info: true
  ticket_id: number
}

export type PootentionalParticipantByIdResp = {}

export type DataForDeleteParticipant = {
  event_id: number
  potential_participant_id: number
}

export type InvitedData<ANY_CONTENT> = {
  content: ANY_CONTENT /* InvitedUser[] */
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  size: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  totalElements: number
  totalPages: number
}

type PotentionalParticipanRes = PromoParam & {
  event_id: number
  partial_phone_number?: string
  potential_participant_id?: number
}

const injectedPromotionApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: (build) => ({
    getPromotionList: build.query<PromoData, PromoParam>({
      query: ({ page = 0, size = 100 }) =>
        `/api/v1/organization/event/statistic?page=${page}&size=${size}`,
      transformResponse: (res: PromoData) => {
        res.content.forEach((card) => {
          card.name =
            card.name.slice(0, 1).toLocaleUpperCase() + card.name.slice(1)
          card.img_url = eventPhoto(card.img_url)
        })
        return res
      },
    }),
    getAllParticipants: build.query<
      InvitedData<PotentionalParticipantById[]>,
      PotentionalParticipanRes
    >({
      query: (
        {
          event_id,
          page,
          size = 20,
          partial_phone_number,
          potential_participant_id,
        } /* ${size}  */,
      ) =>
        `/api/v1/organization/potential-participant?event_id=${event_id}&page=${page}&size=${size}` +
        `${
          partial_phone_number
            ? `&partial_phone_number=${partial_phone_number}`
            : ""
        }` +
        `${
          potential_participant_id
            ? `&potential_participant_id=${potential_participant_id}`
            : ""
        }`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newData, args) => {
        const props = args.arg
        if (props.page === 0) {
          return newData
        } else {
          currentCache.content.push(...newData.content)
          return currentCache
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.page !== previousArg?.page
      },
    }),
    deleteParticipant: build.mutation<undefined, DataForDeleteParticipant>({
      query: ({ event_id, potential_participant_id }) => ({
        url: `/api/v1/organization/potential-participant/${potential_participant_id}/event/${event_id}`,
        method: "DELETE",
      }),
    }),
    getEventInvited: build.query<InvitedData<InvitedUser[]>, InvitedParam>({
      query: ({ eventId, promoterId, phone, page = 0, size = 100 }) =>
        `/api/v1/organization/potential-participant/invited/event/` +
        `${eventId}` +
        `?page=${page}` +
        `&size=${size}` +
        `${promoterId ? `&promoter_id=${promoterId}` : ""}` +
        `${phone ? `&phone_number=${phone}` : ""}`,
      transformResponse: (res: InvitedData<InvitedUser[]>) => {
        res.content.forEach((user) => {
          const bodyPhone = user.phone_number.slice(6)
          const codePhone = user.phone_number.slice(1, 6)
          user.phone_number = `+7 ${codePhone} ${bodyPhone}`
        })
        return res
      },
    }),

    getEventParticipants: build.query<
      InvitedData<InvitedUser[]>,
      ParticipantsParam
    >({
      query: ({
        eventId,
        promoterId,
        phone,
        page = 0,
        size = 100,
        cashierId,
      }) =>
        `/api/v1/organization/potential-participant/participants/event/` +
        `${eventId}` +
        `?page=${page}` +
        `&size=${size}` +
        `${promoterId ? `&promoter_id=${promoterId}` : ""}` +
        `${cashierId ? `&cashier_id=${cashierId}` : ""}` +
        `${phone ? `&phone_number=${phone}` : ""}`,
      transformResponse: (res: InvitedData<InvitedUser[]>) => {
        res.content.forEach((user) => {
          const bodyPhone = user.phone_number.slice(6)
          const codePhone = user.phone_number.slice(1, 6)
          user.phone_number = `+7 ${codePhone} ${bodyPhone}`
        })
        console.log(res)
        return res
      },
    }),
    addNewPotentialParticipant: build.mutation<
      number,
      Omit<
        UserDataForAddToBaseParticipant,
        | "ticket_url"
        | "ticket_id"
        | "invited_via_telegram"
        | "invited_via_whatsapp"
      >
    >({
      query: (userData) => ({
        url: "/api/v1/organization/potential-participant",
        method: "POST",
        body: userData,
      }),
      transformResponse: (respones: number) => {
        console.log(respones)
        const result = Object.values(respones)
        return result[0]
      },
    }),

    pathParticipant: build.mutation<
      number,
      Omit<UserDataForPatchToBaseParticipant, "ticket_url" | "ticket_id">
    >({
      query: (editUserData) => ({
        url: "/api/v1/organization/potential-participant",
        method: "PATCH",
        body: editUserData,
      }),
      transformResponse: (response: number) => {
        console.log(response, "patchUserData")
        const result = Object.values(response)
        return result[0]
      },
    }),
    getEventPromouters: build.query<EventPromouters, PromoutersParams>({
      query: ({ eventId, page = 0, size = 100 }) =>
        `/api/v1/organization/event/${eventId}/promoters/statistic?page=${page}&size=${size}`,
      transformResponse: (res: EventPromouters) => {
        res.content.forEach((card) => {
          card.avatar_url = avatarUrlDecorator(card.avatar_url) ?? ""
        })

        return res
      },
    }),
    getAllEventsCashierOrPromoter: build.query<
      CashierOrPromoterEventsRole,
      void
    >({
      query: () => `/api/v1/organization/event`,
      transformResponse: (res: CashierOrPromoterEventsRole) => {
        return res
      },
    }),
    getEventCashiers: build.query<CashiersData[], number>({
      query: (eventId) =>
        `/api/v1/organization/event/${eventId}/cashiers/statistic`,
      transformResponse: (res: CashiersData[]) => {
        res.forEach((card) => {
          card.avatar_url = avatarUrlDecorator(card.avatar_url) ?? ""
        })

        return res
      },
    }),
    getPotentialEmployees: build.query<
      PotentialParticipants,
      PageSizeNameQuery
    >({
      query: ({ page = 0, size = 20, name = "" }) => {
        if (name) {
          return `/api/v1/user/username/partial/${name}`
        }

        return `/api/v1/user?page=${page}&size=${size}`
      },
      transformResponse: (res: PotentialParticipants) => {
        res.content.forEach((promoter) => {
          promoter.user_avatar = avatarUrlDecorator(promoter.user_avatar) ?? ""
        })
        return res
      },
    }),
    createTicketForParticipant: build.mutation<
      string,
      CreateTicketForParticipantData
    >({
      query: (dataToCreateTicket) => ({
        url: "/api/v1/organization/ticket",
        method: "POST",
        body: dataToCreateTicket,
      }),
      transformResponse: (response: string) => {
        console.log(response)
        return response
      },
    }),
    patchTicketForParticipant: build.mutation<
      string,
      PatchTicketForParticipantData
    >({
      query: (dataToPatchTicket) => ({
        url: "/api/v1/organization/ticket",
        method: "PATCH",
        body: dataToPatchTicket,
      }),
      transformResponse: (response: string) => {
        console.log(response)
        return response
      },
    }),
    getAllCashiers: build.query<EmployeesDataExtended, PageSizeNameQuery>({
      providesTags: ["Cashier"],
      query: ({ page = 0, name = "", size = 20 }) => ({
        url:
          `/api/v1/organization/cashier` +
          `?page=${page}` +
          `&size=${size}` +
          `&partial_name=${name}`,
      }),
      transformResponse: (res: EmployeesDataExtended) => {
        const ids = res.content.map((promoter) => promoter.user_id)

        res.content.forEach((promoter) => {
          promoter.avatar_url = avatarUrlDecorator(promoter.avatar_url) ?? ""
        })

        res.promotersIds = ids

        return res
      },
    }),

    addCashier: build.mutation<AddPromoterResult, number>({
      invalidatesTags: ["Cashier"],
      query: (id) => ({
        url: `/api/v1/organization/cashier`,
        method: "POST",
        body: {
          cashier_user_id: id,
        },
      }),
    }),

    deleteCashier: build.mutation<void, number>({
      invalidatesTags: ["Cashier"],
      query: (id) => ({
        url: `/api/v1/organization/cashier/${id}`,
        method: "DELETE",
      }),
    }),
    getEventStatistic: build.query<EventStatistic, string>({
      providesTags: ["Cashier", "Promotion"],
      query: (args) => ({
        url: `/api/v1/organization/event/${args}/statistic`,
      }),
      transformResponse: (res: EventStatistic) => {
        res.img_url = eventPhoto(res.img_url)
        return res
      },
    }),
    getAllPromoters: build.query<EmployeesDataExtended, PageSizeNameQuery>({
      providesTags: ["Promotion"],
      query: ({ page = 0, name = "", size = 20 }) => ({
        url: `/api/v1/organization/promoter?page=${page}&size=${size}&partial_name=${name}`,
      }),
      transformResponse: (res: EmployeesDataExtended) => {
        const ids = res.content.map((promoter) => promoter.user_id)

        res.content.forEach((promoter) => {
          promoter.avatar_url = avatarUrlDecorator(promoter.avatar_url) ?? ""
        })

        res.promotersIds = ids

        return res
      },
    }),

    addPromoter: build.mutation<AddPromoterResult, number>({
      invalidatesTags: ["Promotion"],
      query: (id) => ({
        url: `/api/v1/organization/promoter`,
        method: "POST",
        body: {
          promoter_user_id: id,
        },
      }),
    }),

    deletePromoter: build.mutation<void, number>({
      invalidatesTags: ["Promotion"],
      query: (id) => ({
        url: `/api/v1/organization/promoter/${id}`,
        method: "DELETE",
      }),
    }),
  }),
})

export const {
  useAddNewPotentialParticipantMutation: useAddNewPotentialParticipant,
  usePatchTicketForParticipantMutation: usePatchTicketForParticipant,
  useGetPromotionListQuery,
  useGetAllParticipantsQuery,
  useLazyGetAllParticipantsQuery,
  useDeleteParticipantMutation,
  usePathParticipantMutation: usePathParticipant,
  useCreateTicketForParticipantMutation: useCreateTicketForParticipant,
  useGetAllEventsCashierOrPromoterQuery: useGetAllEventsCashierOrPromoter,
  useGetEventPromoutersQuery: useGetEventPromouters,
  useGetEventCashiersQuery: useGetEventCashiers,
  useGetPotentialEmployeesQuery: useGetPotentialPeople,
  useAddPromoterMutation: useAddPromoter,
  useGetAllPromotersQuery,
  useDeletePromoterMutation: useDeletePromoter,
  useGetAllCashiersQuery,
  useAddCashierMutation: useAddCashier,
  useDeleteCashierMutation: useDeleteCashier,
  useGetEventInvitedQuery: useGetEventInvited,
  useLazyGetEventInvitedQuery: useLazyGetEventInvited,
  useLazyGetEventParticipantsQuery: useLazyGetEventParticipants,
  useGetEventParticipantsQuery: useGetEventParticipants,
  useGetEventStatisticQuery,
} = injectedPromotionApi

export type PromoData = {
  content: PromoCard[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  pageable: {
    sort: { empty: boolean; sorted: boolean; unsorted: boolean }
    offset: number
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  size: number
  sort: { empty: boolean; sorted: boolean; unsorted: boolean }
  totalElements: number
  totalPages: number
}

export type PromoParam = Omit<PageSizeNameQuery, "name">

export type PromoterData = {
  promoter_id: number
  firstname: string
  lastname: string
  phone_number: string
  avatar_url: string
  invited_count: number
  participant_count: number
  attendance_rate_in_percent: number
}

export type EventPromouters = {
  totalElements: number
  totalPages: number
  size: number
  content: PromoterData[]
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  pageable: {
    offset: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  first: boolean
  last: boolean
  numberOfElements: number
  empty: boolean
}

export type PotentialPerson = {
  username: string
  user_id: number
  user_avatar: string
  first_name: string
  last_name: string
}
export type PotentialParticipants = {
  content: PotentialPerson[]
  pageable: {
    offset: number
    sort: {
      empty: boolean
      sorted: boolean
      unsorted: boolean
    }
    pageNumber: number
    pageSize: number
    paged: boolean
    unpaged: boolean
  }
  last: boolean
  totalElements: number
  totalPages: number
  size: number
  number: number
  sort: {
    empty: boolean
    sorted: boolean
    unsorted: boolean
  }
  first: boolean
  numberOfElements: number
  empty: boolean
}

export type PageSizeNameQuery = {
  page?: number
  size?: number
  name: string
}

export type AddPromoterBody = {
  id: number
}

export type AddPromoterResult = {
  promoter_id: number
  promoter_firstname: string
  promoter_lastname: string
}
