import { FC, useCallback, useEffect, useState } from "react"
import { YandexMap } from "../../../common/yandex/provider/YandexMapProvider"
import { Location, LOCATION, YmapUpdateEvent } from "../types"
import { Container } from "@mui/material"
import { CustomMarkerWithPopup } from "./CustomMarkerWithPopup"
import _ from "lodash"
import { useMapSearchEvents } from "../../../shared/map-events/useMapSearchEvents"
import { z } from "zod"
import { schema } from "../schema"
import { SearchEventDto } from "../../../app/data/tribeSwaggerApi"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";
import { useGetLocationQuery } from "../../../app/data/location/locationApi";
import type { YMapLocationRequest } from "ymaps3";
export type FeedYMapContentProps = {
  ydata: YandexMap
}
type EventsValidModel = z.infer<typeof schema>
const debounce = _.debounce
const isMobile = (): boolean => window.matchMedia("(max-width: 768px)").matches;
export const FeedYMapContent: FC<FeedYMapContentProps> = ({ ydata }) => {

  const { yandexMap, defaultMarker } = ydata
  const {
    YMap,
    YMapDefaultSchemeLayer,
    YMapDefaultFeaturesLayer,
    YMapListener,
  } = yandexMap
  const { query, data } = useMapSearchEvents()
  const {currentFilter}  = useEventFilter()
  const { data:geoData } = useGetLocationQuery()
  const [location, setLocation] = useState<YMapLocationRequest>(LOCATION)
  const updateHandler = useCallback((updateEvent: YmapUpdateEvent) => {
    if (!updateEvent.mapInAction) {
      debounce(() => {
        query(updateEvent.location)
      }, 500)()
    }
  }, [])
  useEffect(() => {
    if (geoData) {
      setLocation({
        center: [geoData.longitude, geoData.latitude], // starting position [lng, lat]
        zoom: 12, // starting zoom
      })
    }
  },[geoData])
  return (
    <Container sx={{height: isMobile() ? "100vh" : null}}>
      <YMap location={location}>
        <YMapDefaultSchemeLayer />
        <YMapDefaultFeaturesLayer />
        <CustomMarkerWithPopup events={reduceValidModel(data)} />
        <YMapListener onUpdate={updateHandler} />
      </YMap>
    </Container>
  )
}

const validModel = (dto: SearchEventDto): z.infer<typeof schema> | null => {
  const result = schema.safeParse(dto)
  if (result.success) {
    return result.data
  } else {
    return null
  }
}

const reduceValidModel = (
  data: SearchEventDto[] | undefined,
): EventsValidModel[] => {
  if (!data) return []
  return data.reduce<EventsValidModel[]>(
    (prev: EventsValidModel[], current) => {
      const model = validModel(current)
      if (model) {
        return [...prev, model]
      } else {
        return prev
      }
    },
    [],
  )
}
