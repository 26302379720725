import { Tab } from "./components/filtered/FilterBySelectTab/TabsTypes/TabTypes"
import { TabPromotionTypes } from "./types/TabPromotionTypes"
import { TabSettingsTypes } from "./types/TabSettingsTypes"

import chatIcon from "./assets/svg/navigate/chatNav.svg"
import eventIcon from "./assets/svg/navigate/eventNav.svg"
import favoritesIcon from "./assets/svg/navigate/favoritesNav.svg"
import friendsIcon from "./assets/svg/navigate/friendsNav.svg"
import myEventsIcon from "./assets/svg/navigate/myEventsNav.svg"
import profileIcon from "./assets/svg/navigate/profileNav.svg"
import CashierIcon from "./assets/svg/promotion-icon/cashierIcon"
import HomeIcon from "./assets/svg/promotion-icon/homeIcon"
import QrCodeIcon from "./assets/svg/promotion-icon/qrCodeIcon"

import ProfileIcon from "./assets/svg/menu-icons-pack/user-icon-menu.svg?react"
import EventIcon from "./assets/svg/menu-icons-pack/world-icon-menu.svg?react"
import SettingsIcon from "./assets/svg/menu-icons-pack/settings-icon-menu.svg?react"
import OrganizerIcon from "./assets/svg/menu-icons-pack/diamond-icon-menu.svg?react"
import SignoutIcon from "./assets/svg/menu-icons-pack/signout-icon-menu.svg?react"
import SecurityIcon from "./assets/svg/menu-icons-pack/security-icon-menu.svg?react"
import InterestsIcon from "./assets/svg/menu-icons-pack/interests-icon-menu.svg?react"
import NotificationIcon from "./assets/svg/menu-icons-pack/notification-icon-menu.svg?react"
import GeolocationIcon from "./assets/svg/menu-icons-pack/geolocation-icon-menu.svg?react"
import AccountIcon from "./assets/svg/menu-icons-pack/account-icon-menu.svg?react"
import ExitIcon from "./assets/svg/menu-icons-pack/exit-icon-menu.svg?react"
import FaqIcon from "./assets/svg/menu-icons-pack/faq-icon-menu.svg?react"
import ColorthemeIcon from "./assets/svg/menu-icons-pack/fire-icon-menu.svg?react"
import SoundIcon from "./assets/svg/menu-icons-pack/notification-sound-icon-menu.svg?react"
import BrowserIcon from "./assets/svg/menu-icons-pack/notification-browser-icon-menu.svg?react"
import ChatIcon from "./assets/svg/menu-icons-pack/notification-chat-icon-menu.svg?react"
import LikeIcon from "./assets/svg/menu-icons-pack/favorite-icon-menu.svg?react"

export const navigateItems = [
  { title: "Cобытия", link: "/", icon: eventIcon },
  { title: "Профиль", link: "/profile", icon: profileIcon },
  { title: "Друзья", link: "/friends", icon: friendsIcon },
  { title: "Чат", link: "/chat", icon: chatIcon },
  { title: "Мои события", link: "", icon: myEventsIcon },
  { title: "Избранное", link: "/favorite", icon: favoritesIcon },
]

export const CATEGORY_SHOULD_DISPLAYED = ["/", "/map"]

export const MAX_VALUE_OF_NOTIFICATION_ON_THE_BELL = 99

export const ratingComments: (string | number)[][] = [
  ["Ужасно", 1],
  ["Терпимо", 2],
  ["Средне", 3],
  ["Хорошо", 4],
  ["Супер", 5],
]

export const monthsByIndex = [
  "Января",
  "Февраля",
  "Марта",
  "Апреля",
  "Мая",
  "Июня",
  "Июля",
  "Августа",
  "Сентября",
  "Октября",
  "Ноября",
  "Декабря",
]

export const CalendarCardIcon = {
  ru: [
    "Янв",
    "Фев",
    "Мар",
    "Апр",
    "Май",
    "Июн",
    "Июл",
    "Авг",
    "Сен",
    "Окт",
    "Ноя",
    "Дек",
  ],
  en: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
}

export const monthByLanguage = {
  ru: [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
  ],
  eng: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
}

export const month = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
]

export const menuIconsPack = [
  {
    icon: EventIcon,
    alt: {
      ru: "Иконка события",
      en: "Events icon",
    },
    path: "/",
    text: "События",
  },
  {
    icon: ProfileIcon,
    alt: {
      ru: "Иконка профиля",
      en: "Profile icon",
    },
    path: "/profile",
    text: "Профиль",
  },
  {
    alt: {
      ru: "Иконка темы",
      en: "Theme icon",
    },
    path: "#",
    themeText: {
      ru: {
        light: "Светлая тема",
        dark: "Темная тема",
      },
      en: {
        light: "Light theme",
        dark: "Dark theme",
      },
    },
  },
]

export const profileIconsPack = [
  {
    icon: ProfileIcon,
    alt: {
      ru: "Иконка профиля",
      en: "Profile icon",
    },
    path: "/profile/myevents",
    text: "Профиль",
  },
  {
    icon: SettingsIcon,
    alt: {
      ru: "Иконка настроек",
      en: "Settings icon",
    },
    path: "/profile/settings",
    text: "Настройки",
  },
  {
    alt: {
      ru: "Иконка темы",
      en: "Theme icon",
    },
    path: "#",
    themeText: {
      ru: {
        light: "Светлая тема",
        dark: "Темная тема",
      },
      en: {
        light: "Light theme",
        dark: "Dark theme",
      },
    },
  },
  {
    icon: OrganizerIcon,
    alt: {
      ru: "Иконка кабинета организатора",
      en: "Organizer's Dashboard icon",
    },
    path: "/profile/promotion",
    text: "Кабинет организатора",
  },
  {
    icon: SignoutIcon,
    alt: {
      ru: "Иконка выхода",
      en: "Exit icon",
    },
    path: "/",
    text: "Выход",
  },
]

export const dayTab: Tab = {
  paramKey: "day",
  title: "Время проведения",
  tabsName: ["Утро", "День", "Вечер", "Ночь"],
  tabsValue: {
    Утро: "MORNING",
    День: "AFTERNOON",
    Вечер: "EVENING",
    Ночь: "NIGHT",
  },
}

export const costTab: Tab = {
  paramKey: "isFree",
  title: "Стоимость",
  tabsName: ["Все", "Платные", "Бесплатные"],
  tabsValue: {
    Все: undefined,
    Платные: true,
    Бесплатные: false,
  },
}

export const AlcoholTab: Tab = {
  paramKey: "isPresenceOfAlcohol",
  title: "Алкоголь",
  tabsName: ["Нет", "Да", "Все"],
  tabsValue: {
    Нет: false,
    Да: true,
    Все: undefined,
  },
}

export const RestrictionTab: Tab = {
  paramKey: "isEighteenYearLimit",
  title: "Ограничения",
  tabsName: ["Есть", "18+", "Все"],
  tabsValue: {
    Есть: true,
    "18+": false,
    Все: undefined,
  },
}

export const selectValues = ["DATE", "DISTANCE", "ALCOHOL"]

export const sideMenuPromotionTabs: TabPromotionTypes[] = [
  {
    title: "Мероприятия",
    Icon: HomeIcon,
    link: "/profile/promotion/events",
  },
  {
    title: "Промоутеры",
    Icon: QrCodeIcon,
    link: "/profile/promotion/promotes",
  },
  {
    title: "Кассиры",
    Icon: CashierIcon,
    link: "/profile/promotion/cashier",
  },
]

export const sideMenuSettingsTabs: TabSettingsTypes[] = [
  {
    id: "profile",
    label: "Аккаунт и внешний вид",
    icon: AccountIcon,
  },
  {
    id: "security",
    label: "Безопасность",
    icon: SecurityIcon,
  },
  {
    id: "interests",
    label: "Интересы",
    icon: InterestsIcon,
  },
  {
    id: "notifications",
    label: "Настройка уведомлений",
    icon: NotificationIcon,
  },
]

export const notificationsSettings = [
  {
    icon: SoundIcon,
    alt: {
      ru: "Иконка для уведомлений со звуком",
      en: "Sound notification icon",
    },
    text: "Уведомления со звуком",
  },
  {
    icon: ChatIcon,
    alt: {
      ru: "Иконка для уведомлений о сообщениях в чат",
      en: "Chat notification icon",
    },
    text: "Сообщения в чат",
  },
  {
    icon: LikeIcon,
    alt: {
      ru: "Иконка для уведомлений мне нравится",
      en: "Favorite notification icon",
    },
    text: "Отметки мне нравится",
  },
  {
    icon: BrowserIcon,
    alt: {
      ru: "Иконка для браузерных уведомлений",
      en: "Browser notification icon",
    },
    text: "Браузерные уведомления",
  },
]

export const interests = [
  /* id должно совпадать с id в категориях
  src\common\category\useCategoryProvider.ts
  localCategory: CategoryInfo[]*/
  {
    id: 15,
    text: "Любовь",
  },
  {
    id: 4,
    text: "Вечеринки",
  },
  {
    id: 17,
    text: "Игры",
  },
  {
    id: 18,
    text: "Шопинг",
  },
  {
    id: 1,
    text: "Спорт",
  },
  {
    id: 2,
    text: "Поездки",
  },
  {
    id: 5,
    text: "Обучение",
  },
  {
    id: 7,
    text: "Для детей",
  },
  {
    id: 8,
    text: "Отдых за городом",
  },
  {
    id: 9,
    text: "Религия",
  },
  {
    id: 12,
    text: "Туризм",
  },
  {
    id: 13,
    text: "Прогулки",
  },
  {
    id: 16,
    text: "Культура",
  },
  {
    id: 14,
    text: "Для животных",
  },
  {
    id: 19,
    text: "Другое",
  },
]
