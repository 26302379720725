import { FC, useState } from "react"
import s from "./styles.module.css"

interface MyFormValues {
  type: string
  placeholder: string
  value: string
  handleChange: (e: any) => void
  name: string
  title?: string
  onBlur: (e: any) => void
  searchClick?: (e: any) => void
  onKeyPress?: (e: any) => void
  errorText: string | null
  isSearch?: boolean
  onChange: (e: any) => void
  min?: string
  max?: string
}

const InputDate: FC<MyFormValues> = ({
  min,
  max,
  type = "text",
  errorText,
  onBlur,
  placeholder,
  name,
  value,
  title = null,
  onChange,
  onKeyPress = () => {},
}) => {
  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => setIsFocused(true)
  const handleBlur = (e: any) => {
    setIsFocused(false)
    onBlur(e)
  }

  return (
    <div style={{ width: "100%" }}>
      {title && <span className={s.title}>{title}</span>}
      <div className={s.input_box}>
        <input
          min={min}
          max={max}
          type={"datetime-local"}
          style={{
            color: isFocused
              ? "var(--filter-range-active)"
              : "var(--secondary-text)",
            borderBottom: isFocused
              ? "2px solid var(--filter-range-active)"
              : "2px solid transparent",
          }}
          onKeyDown={onKeyPress}
          name={name}
          onChange={onChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          value={value}
          className={s.input}
          placeholder={placeholder}
        />
      </div>
      {errorText && <p className={s.error_text}>{errorText}</p>}
    </div>
  )
}

export default InputDate
