import { Avatar, Box, Typography } from "@mui/material"
import { FC } from "react"
import GradientBtn from "../../../../components/GradientBtn"
import GradientTextBtn from "../../../../components/GradientTextBtn"
import { Action } from "../index"
import AvatarFallback from "../../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import * as s from "./style"
import { PotentialPerson } from "../../../../app/data/promotion/promotionApi"

type Props = {
  handleClickAction: (action: Action) => void
  person: PotentialPerson | null
  btnTitle: string
}

const SubmitAddPerson: FC<Props> = ({
  person,
  handleClickAction,
  btnTitle,
}) => (
  <>
    <Box sx={s.contentCont}>
      <Avatar alt={person?.username} src={person?.user_avatar} sx={s.avatar}>
        <AvatarFallback
          style={{
            width: "100px",
            height: "100px",
            borderRadius: "50%",
          }}
        />
      </Avatar>

      <Box sx={s.contactInfoStyle}>
        <Typography
          variant="body1"
          fontWeight={500}
          color={"var(--secondary-text)"}
        >
          {person?.username}
        </Typography>
      </Box>

      {person?.first_name && person.last_name && (
        <Box sx={s.contactInfoStyle}>
          <Typography
            variant="body1"
            fontWeight={500}
            color={"var(--secondary-text)"}
          >
            {`${person.first_name} ${person.last_name}`}
          </Typography>
        </Box>
      )}
    </Box>

    <Box sx={s.btnCont}>
      <GradientTextBtn
        title="Назад"
        handleAction={() => handleClickAction("left")}
        btnStyle={{ ...s.btnBack, minWidth: "100px", minHeight: "48px" }}
      />

      <GradientBtn
        title={btnTitle}
        handleAction={() => handleClickAction("submit")}
        btnStyle={{ ...s.btnSubmit }}
      />
    </Box>
  </>
)

export default SubmitAddPerson
