import React, { FC } from "react"
import style from "./style.module.css"
import Google from "../../assets/svg/googleIcon.svg"
import VK from "../../assets/svg/VKIcon.svg"
import GoogleAuthButton from "./AuthButtons/GoogleAuthButton";

const SocialLinks: FC = () => {
  return (
    <div className={style.social_container}>
      <span className={style.title}>Войти с помощью:</span>
      <div className={style.image_box}>
        {/*<img src={VK} alt="VK_icon" />*/}
        <GoogleAuthButton/>
      </div>
    </div>
  )
}

export default SocialLinks
