import { min } from "lodash"

export const container = { width: "100%", overflow: "auto" }

export const searchCont = {
  width: "max-content",
  padding: "20px 0",
  margin: "0 20px",
  minWidth: "350px",
}

export const cardsCont = {
  padding: "0 20px",
  display: "flex",
  flexWrap: "wrap",
  gap: "20px",
  marginBottom: "20px",
}
