import { EventsCategoryActions } from "../../hooks/profile_page_hooks/useProfileEventFieldData"
import { useAppSelector } from "../../app/hooks"
import s from "./styles.module.scss"
import { Link } from "react-router-dom"

type ProfileEventsCheckboxProps = {
  onSetEventType: (evtType: EventsCategoryActions) => void
  chekedType: EventsCategoryActions
}

const ProfileEventsCheckbox = ({
  onSetEventType,
  chekedType,
}: ProfileEventsCheckboxProps) => {
  const isPromoter = useAppSelector(
    (state) => state.userProfileState.info.isPromoter_or_Cashier,
  )

  // Для удобства тестировщиков
  isPromoter && console.log("промоутер/кассир: да")

  return (
    <div className={s.events}>
      <div className={s.events_header}>
        <div className={s.event_header_nav}>
          {/*//TODO: левая сторона пока спрятана - ждет пока подключим карту
          <div className={s.left_side}>
            <div className={s.evt_type_wrapper}>
              <input
                type="radio"
                id="feed"
                name="evt_type"
                defaultChecked
                onChange={() => console.log("показываем ленту")}
              />
              <label htmlFor="feed">Лента</label>
            </div>
            <div className={s.evt_type_wrapper}>
              <input
                type="radio"
                id="on_map"
                name="evt_type"
                onChange={() => console.log("показываем карту")}
              />
              <label htmlFor="on_map">На карте</label>
            </div>
          </div>*/}
          {isPromoter && (
            <div className={s.evt_type_wrapper}>
              <Link
                to={"tribepromotion"}
                onClick={() => {
                  onSetEventType("Promotion")
                }}
              >
                <input
                  type="radio"
                  id="promotion"
                  name="evt_type"
                  checked={chekedType === "Promotion"}
                  readOnly
                />
                <label htmlFor="promotion">Tribe promotion</label>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProfileEventsCheckbox
