import { FilteredListResult } from "./types";

import { useEffect } from "react";
import { useLazySearchFilteredEventsQuery } from "../../../../app/data/events/eventsApi";
import useFeedPaginationState from "../useFeedPagination";
import useEventFilter from "../../../filter/hooks/useEventFilter";
import { ioErrorOf } from "../../../../app/data/errors";
import { useCategoryProvider } from "../../../../common/category/useCategoryProvider";

const useFeedEventsFilteredList = (): FilteredListResult => {
  const  {currentFilter}  = useEventFilter()
  const {isLoading: isLoadCategories} = useCategoryProvider()
  const [lazyQuery, {isLoading, error, data}] = useLazySearchFilteredEventsQuery()
  const {page, size} = useFeedPaginationState()
  useEffect(() => {
      lazyQuery({
        page: page,
        size: size,
        filter: currentFilter
      })

  }, [ currentFilter, page])

  return {
    isLoading: isLoading,
    error: ioErrorOf(error) ,
    filteredList: data?.content ,
    isEmpty: data?.metadata.totalElements === 0,
    pagination: data?.metadata && data.metadata
  }
}
export default useFeedEventsFilteredList