import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, CardActions } from "@mui/material"
import classNames from "classnames"

import { TStepProps } from "../../../types"
import Title from "../../common/title"
import { getRussianDate } from "../step_5/utils"

import ConfirmIcon from "../../../../../assets/svg/confirm.svg?react"
import LocationIcon from "../../../../../assets/svg/menu-icons-pack/placepin-icon-menu.svg?react"
import CalendarIcon from "../../../../../assets/svg/menu-icons-pack/calendar-icon-menu.svg?react"
import TimeIcon from "../../../../../assets/svg/menu-icons-pack/clock-icon-menu.svg?react"
//import LocationIcon from "../../../../../assets/svg/location_item.svg?react"
//import CalendarIcon from "../../../../../assets/svg/calendar_item.svg?react"
//import TimeIcon from "../../../../../assets/svg/time_item.svg?react"

import {
  cardBtnExtra,
  cardBtnExtraText,
  cardBtnImGoing,
  cardBtnsCont,
} from "../../../../../components/CardEvent/CardEvent/CardEventStyles"
import s from "./styles.module.css"

const Confirm: FC<TStepProps> = ({ makeStep, handlerClose, step, formik }) => {
  const navigate = useNavigate()

  // @ts-ignore TODO: Косячные типы
  const { event_name, is_free, event_address, start_time, event_id } =
    formik.values
  const dateStart = new Date(start_time)
  const hours = dateStart.getHours()
  const minutes = dateStart.getMinutes()
  const time = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`

  const handleCreateEvent = () => {
    navigate(`fid/${event_id}`)
    if (handlerClose) {
      handlerClose()
    }
  }
  const handleInvite = () => {
    makeStep("invite")
  }

  return (
    <div className={s.container}>
      <Title align={"center"} title={"Поздравляем!"} />

      <div className={s.icon_box}>
        <ConfirmIcon />
      </div>

      <div className={s.description}>
        <p className={s.text}>Мероприятие успешно создано!!!</p>
        <p className={classNames(s.text, s.text_bold)}>{event_name}</p>
        <div className={s.description_info}>
          <div className={s.card_info_item}>
            <LocationIcon />
            <p className={s.text}>{`${event_address.city}`}</p>
          </div>
          <div className={classNames(s.card_info_item)}>
            <CalendarIcon />
            <p className={s.text}>{getRussianDate(start_time)}</p>
          </div>
          {dateStart && (
            <div className={s.card_info_item}>
              <TimeIcon />
              <p className={s.text}>{time}</p>
            </div>
          )}
          <p>
            Участие: <b>{`${is_free ? "бесплатно" : "платно"}`}</b>
          </p>
        </div>

        <p className={s.text}>
          Администрация Tribe проверяет событие на соответствие установленным
          нормам и законам РФ. После проверки событие опубликуется.
        </p>
        <p className={s.text}>
          Если нарушения будут выявлены, мы оповестим вас об этом и статус
          события сменится на “На доработку”.
        </p>
      </div>

      {/*<CardActions sx={{*/}
      {/*  ...cardBtnsCont,*/}
      {/*  width: "100%",*/}
      {/*  marginTop: "50px",*/}
      {/*  flexDirection: "column",*/}
      {/*  maxWidth: "220px",*/}
      {/*  margin: "0 auto"*/}
      {/*}}>*/}
      {/*  <Button onClick={handleInvite} sx={{*/}
      {/*    ...cardBtnImGoing, height: "48px"*/}
      {/*  }}>*/}
      {/*    <p>Добавить участников</p>*/}
      {/*  </Button>*/}

      {/*  <Button onClick={handleCreateEvent} variant="contained"*/}
      {/*          sx={{ ...cardBtnExtra, minHeight: "48px" }}>*/}
      {/*    <p style={cardBtnExtraText}>*/}
      {/*      Перейти в мероприятие*/}
      {/*    </p>*/}
      {/*  </Button>*/}

      {/*</CardActions>*/}
    </div>
  )
}

export default Confirm
