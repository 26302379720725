import { useAppSelector } from "../../app/hooks"
import { selectUserId } from "../../app/data/appTokensStore"
import {
  useGetUserInvitationEventsQuery,
  useGetUserOrganizationEventsQuery,
  useGetUserParticipantEventsQuery,
} from "../../app/data/userDataSlice/userApi"
import { EventInfoProfile, ResponseStatus, SelfEvents } from "./types"
import { ioErrorOf } from "../../app/data/errors"
import {
  PagebleResponse,
  UserEventInfo,
} from "../../app/data/userDataSlice/types"
import eventPhoto from "../../app/data/avatarUrl"
import {
  CashierOrPromoterEventsRole,
  useGetAllEventsCashierOrPromoter,
} from "../../app/data/promotion/promotionApi"
import { EventModel } from "../../types"
import { useEffect } from "react"

const selfEventAdapter = (
  dto: PagebleResponse<UserEventInfo> | undefined,
): SelfEvents | undefined => {
  if (dto) {
    return {
      events: dto.content.map((e) => ({
        id: e.id,
        event_name: e.event_name,
        start_time: e.start_time,
        event_photo_url: eventPhoto(e.event_photo_url[0]) ?? "",
        city: e.city,
        status: e.event_status,
      })),
      pagination: {
        size: dto.size,
        page: dto.number,
        first: dto.first,
        last: dto.last,
        total: dto.totalPages,
        singlePage: dto.totalPages === 1,
      },
    }
  } else {
    return undefined
  }
}

export const useSelfEvents = (
  page: number,
  size: number,
): ResponseStatus<SelfEvents> => {
  const credentials = useAppSelector(selectUserId)
  const { data, isLoading, error } = useGetUserOrganizationEventsQuery({
    userId: credentials!!,
    page: page,
    size: size,
    sort: "asc",
  })

  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    state: selfEventAdapter(data),
  }
}
const invitedEventAdapter = (
  dto: UserEventInfo[] | undefined,
): EventInfoProfile[] | undefined => {
  if (dto) {
    return dto.map((e) => ({
      id: e.id,
      event_name: e.event_name,
      start_time: e.start_time,
      event_photo_url: eventPhoto(e.event_photo_url[0]) ?? "",
      city: e.city,
      status: e.event_status,
    }))
  } else {
    return undefined
  }
}
export const useInvitedEvents = (): ResponseStatus<EventInfoProfile[]> => {
  const credentials = useAppSelector(selectUserId)
  const { isLoading, error, data } = useGetUserInvitationEventsQuery({
    userId: credentials!!,
  })

  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    state: invitedEventAdapter(data),
  }
}

export const useWillGoEvents = (
  page: number,
  size: number,
): ResponseStatus<SelfEvents> => {
  const credentials = useAppSelector(selectUserId)
  const { data, isLoading, error } = useGetUserParticipantEventsQuery({
    userId: credentials!!,
    page: page,
    size: size,
    sort: "asc",
  })

  return {
    isLoading: isLoading,
    error: ioErrorOf(error),
    state: selfEventAdapter(data),
  }
}

const promotionEventAdapter = (
  dto: CashierOrPromoterEventsRole,
): EventInfoProfile[] | undefined => {
  const promoEvents = dto
  if (promoEvents) {
    return promoEvents.content.map((e) => ({
      id: e.id,
      event_name: e.name,
      start_time: e.start_date_time,
      event_photo_url: eventPhoto(e.image_url),
      city: e.city,
      status: e.event_status,
    }))
  } else {
    return []
  }
}

export const usePromotionEvents = (): ResponseStatus<EventInfoProfile[]> => {
  const { isLoading, error, data } = useGetAllEventsCashierOrPromoter()

  return {
    state: promotionEventAdapter(data as CashierOrPromoterEventsRole),
    isLoading,
    error: ioErrorOf(error),
  }
}
