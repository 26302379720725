import { SxProps, Theme } from "@mui/material"

export const contactsContainer: SxProps<Theme> = {
  height: "100%",
  width: "100%",
  maxHeight: "408px",
  display: "flex",
  flexDirection: "column",
  gap: 0,
  borderRadius: "8px",
  backgroundColor: "var(--inputs-bg)",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "var(--unselected-text) transparent",
}

export const contactItem: SxProps<Theme> = {
  display: "flex",
  gap: "16px",
  minHeight: "68px",
  height: "auto",
  padding: "8px",
  position: "relative",
  alignItems: "center",
  cursor: "pointer",
  overflow: "hidden",
  border: "1px solid transparent",
  borderRadius: "8px",
  transition: "all 0.2s ease",
  "&:hover": {
    border: "1px solid var(--bg-outside-modal)",
  },
}

export const contactAvatar: SxProps<Theme> = {
  width: "50px",
  height: "50px",
  borderRadius: "50%",
}

export const contactInfo: SxProps<Theme> = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  columnGap: "8px",
  rowGap: "4px",
  "&::after": {
    content: "''",
    position: "absolute",
    overflow: "hidden",
    top: 0,
    left: "-8px",
    width: "500px",
    height: "500px",
    backgroundColor: "var(--bg-outside-modal)",
    borderRadius: "50%",
    opacity: 0,
    transition: "opacity 0.5s ease",
  },
  "&:hover::after": {
    opacity: 1,
    transition: "opacity 0.5s ease",
  },
  "@media screen and (max-width: 380px)": {
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: 0,
  },
}

export const btnStyle: SxProps<Theme> = {
  boxShadow: "none",
  borderRadius: "16px",
  height: "48px",
  padding: "12px 16px 12px 16px",
  fontSize: "16px",
  fontWeight: 500,
  minWidth: "152px",
  maxWidth: "152px",
  width: "fit-content",
  textTransform: "none",
  alignSelf: "center",
}

export const contactInfoStyle: SxProps<Theme> = {
  display: "flex",
  gap: "4px",
  flexDirection: "column",
  color: "var(--primary-text)",
}

export const contactsContOuter: SxProps<Theme> = {
  position: "relative",
  bgcolor: "pink",
  borderRadius: "8px",
  padding: "16px",
  WebkitUserSelect: "none" /* Safari */,
  msUserSelect: "none" /* IE 10 and IE 11 */,
  userSelect: "none",
}

export const contactsContInner: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  minHeight: "300px",
  maxHeight: "372px",
  overflow: "auto",
}

export const contactAvatarStyle: SxProps<Theme> = {
  width: "50px",
  height: "50px",
}

export const contactStyle: SxProps<Theme> = {
  display: "flex",
  gap: "20px",
  alignItems: "center",
  p: "10px",
  maxHeight: "60px",
  position: "relative",
  cursor: "pointer",
  overflow: "hidden",
  minHeight: "60px",
  border: "1px solid transparent",
  borderRadius: "8px",
  transition: "all 0.2s ease",
  "&:hover": {
    border: "1px solid var(--bg-outside-modal)",
    borderRadius: "8px",
  },

  "&::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: "-11px",
    width: "500px",
    height: "500px",
    backgroundColor: "var(--bg-outside-modal)",
    borderRadius: "50%",
    zIndex: -1,
    transition: "all 0.2s ease",
    opacity: 0,
  },

  "&:hover::before": {
    content: "''",
    position: "absolute",
    top: 0,
    left: "-11px",
    width: "500px",
    height: "500px",
    backgroundColor: "var(--bg-outside-modal)",
    borderRadius: "50%",
    zIndex: -1,
    opacity: 1,
  },
}

export const buttonsBlock: SxProps<Theme> = {
  width: "100%",
  height: "64px",
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  paddingBottom: "6px",
}

export const btnDisabled = {
  ...btnStyle,
  background: "var(--borders)",

  "&:hover": {
    background: "var(--borders)",
  },
}
