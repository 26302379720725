import { SxProps, Theme } from "@mui/material"

export const inputCont = {
  display: "flex",
  width: "100%",
  justifyContent: "flex-start",
  position: "relative",
}

export const inputStyle: SxProps<Theme> = {
  display: "inline-flex",
  position: "relative",
  bgcolor: "var(--inputs-bg)",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "8px 12px",
  gap: "8px",
  borderBottom: "2px solid transparent",
  minHeight: "36px",
  borderRadius: "8px",
  caretColor: "var(--secondary-text)",
  color: "var(--secondary-text)",
  width: "100%",
  maxWidth: "360px",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  transition: "color 0.3s ease",
  "input::placeholder": {
    color: "var(--input-placeholder)",
    transition: "color 0.3s ease",
  },
  "&:has(input:focus)": {
    borderBottom: "2px solid var(--filter-range-active)",
    transition: "all 0.3s ease",
    input: {
      color: "var(--filter-range-active)",
      transition: "color 0.3s ease",
    },
  },
}

export const clearInputStyle: SxProps<Theme> = {
  cursor: "pointer",
  fontSize: "20px",
  transition: "all 0.3s ease",
  stroke: "var(--filter-range-active)",
  fill: "var(--filter-range-active)",
}

export const alertStyle = {
  width: "300px",
  height: "60px",
  borderRadius: "8px",
  position: "absolute",
  top: "45px",
  zIndex: 1500,
  alignItems: "center",
  textAlign: "center",
  textWrap: "wrap",
  fontSize: "1rem",
  overflow: "hidden",
}
