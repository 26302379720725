import Box from "@mui/material/Box"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import * as React from "react"
import { FC } from "react"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

const CustomTabPanel: FC<TabPanelProps> = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export type TabItem = {
  title: string
  content: React.ReactNode
}

export interface TabLayoutProps {
  tabs: TabItem[]
}

const TabLayout: FC<TabLayoutProps> = (props: TabLayoutProps) => {
  const [value, setValue] = React.useState(0)
  const { tabs } = props
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="login and signup tabs"
          centered
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "var(--filter-range-active)",
            },
          }}
        >
          {tabs.map((item: TabItem, index: number) => {
            return (
              <Tab
                label={item.title}
                key={index}
                sx={{
                  color: "var(--unselected-text)",
                  "&.Mui-selected": {
                    color: "var(--primary-text)",
                  },
                }}
                {...a11yProps(index)}
              />
            )
          })}
        </Tabs>
      </Box>

      {tabs.map((item: TabItem, index: number) => {
        return (
          <CustomTabPanel index={index} key={index} value={value}>
            {item.content}
          </CustomTabPanel>
        )
      })}
    </Box>
  )
}

export default TabLayout
