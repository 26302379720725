import { Close } from "@mui/icons-material"
import { Box, Modal, ThemeProvider, Typography } from "@mui/material"
import { ReactNode } from "react"
import Loading from "../Loading"
import * as s from "./style"

export type Action = "left" | "right" | "submit" | "close"

type Props = {
  isModal: boolean
  handleClickAction: () => void
  cardTitle: string
  isLoading: boolean
  errorTitle?: string
  children: ReactNode
  animation: string
  cardName?: string
}

const capitalize = (str: string) => str[0].toUpperCase() + str.slice(1)

export default function PromotionModal({
  isModal,
  handleClickAction,
  cardTitle,
  isLoading,
  errorTitle,
  children,
  animation,
  cardName,
}: Props) {
  return (
    <ThemeProvider theme={s.theme}>
      <Modal open={isModal}>
        <Box sx={{ ...s.cardCont, animation: `${animation} 0.35s ease` }}>
          <Box sx={s.style}>
            <Close onClick={handleClickAction} sx={s.closeBtnStyle} />

            {isLoading && !errorTitle && (
              <Loading styles={{ position: "absolute", top: 0, left: 0 }} />
            )}

            {!isLoading && errorTitle && (
              <Box>
                <Typography sx={s.headerTitle}>{errorTitle}</Typography>
              </Box>
            )}

            <Box sx={s.guts}>
              <Box sx={s.header}>
                <Typography sx={s.headerTitle}>{cardTitle}</Typography>
                {cardName && (
                  <Typography
                    variant="body1"
                    fontWeight={400}
                    sx={s.headerSubtitle}
                  >
                    {capitalize(cardName)}
                  </Typography>
                )}
              </Box>
              <Box sx={s.content}>{children}</Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </ThemeProvider>
  )
}
