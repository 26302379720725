import { createTheme, keyframes, SxProps, Theme } from "@mui/material"
import { bottom } from "@popperjs/core"

export const theme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--bg-outside-modal) !important",
          transition: "all 0.3s ease",
        },
      },
    },
  },
})

export const cardCont: SxProps<Theme> = {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  display: "flex",
  height: "100%",
  width: "100%",
  padding: "16px",
  justifyContent: "center",
  alignItems: "center",
}

export const style: SxProps<Theme> = {
  position: "relative",
  width: "100%",
  height: "100%",
  maxWidth: "392px",
  maxHeight: "686px",
  display: "grid",
  gridTemplateRows: "24px 1fr",
  gap: "8px",
  borderRadius: "16px",
  boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.08)",
  backgroundColor: "var(--modal-bg-color)",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontWeight: "400",
  lineHeight: "24px",
  letterSpacing: "0.5px",
  textAlign: "left",
  color: "var(--secondary-text)",
  padding: "8px",
  boxSizing: "border-box",
}

export const closeBtnStyle: SxProps<Theme> = {
  width: "24px",
  height: "24px",
  marginLeft: "auto",
  cursor: "pointer",
}

export const guts: SxProps<Theme> = {
  padding: "0px 8px 24px",
  display: "flex",
  height: "100%",
  width: "100%",
  flexDirection: "column",
  gap: "16px",
  overflowY: "auto",
  scrollbarWidth: "thin",
  scrollbarColor: "transparent transparent",
  scrollbarGutter: "stable both-edges",
}

export const header: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
  gap: "8px",
  alignItems: "center",
}

export const headerTitle: SxProps<Theme> = {
  fontSize: "20px",
  fontWeight: "800",
  lineHeight: "24px",
  color: "var(--secondary-text)",
  overflowWrap: "anywhere",
  textAlign: "left",
}

export const headerSubtitle = {
  color: "var(--secondary-text)",
  overflowWrap: "anywhere",
}

export const content: SxProps<Theme> = {
  height: "100%",
  gap: "16px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}

export const fadeOut = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`

export const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 1;
}
`

export const slideRight = keyframes`
0% {
  transform: translateX(600px);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
`

export const slideLeft = keyframes`
0% {
  transform: translateX(-600px);
  opacity: 0;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
`
