import { swaggerSplitApi as api } from "../swaggerApi"
import { AuthSocialArgs, AuthSocialResponse } from "./types";
import { setUserCredentials } from "../appTokensStore";
import { hide } from "../../../pages/signIn/SignInSlice";

const authApi = api.injectEndpoints({
  overrideExisting: true,
  endpoints: build => ({
    googleLogin: build.mutation<AuthSocialResponse, AuthSocialArgs>({
      query: (arg:AuthSocialArgs)=> ({
        url: `/api/v1/auth/login/google/code`,
        method: "POST",
        body: arg,
      }),
      onQueryStarted: async (_, {dispatch, queryFulfilled}) => {
        const {data} = await queryFulfilled
        dispatch(
          setUserCredentials({
            applicationToken: data.access_token,
            refreshToken: data.refresh_token,
            userId: data.user_id,
          }),
        )
        dispatch(hide())
      }
    })
  })
})

export const {useGoogleLoginMutation} = authApi