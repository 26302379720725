import { CSSProperties } from "react"

export const searchCont = {
  display: "flex",
  position: "relative",
  width: "100%",
  flexGrow: 1,
  transition: "all 3s ease",
  "@media (min-width: 760px)": {
    maxWidth: "260px",
  },
  "@media (min-width: 960px)": {
    maxWidth: "360px",
  },
  "@media (min-width: 1200px)": {
    maxWidth: "394px",
  },
  "@media (min-width: 1600px)": {
    maxWidth: "594px",
  },
}

export const searchInput: CSSProperties = {
  width: "100%",
  padding: "0px 40px",
  position: "relative",
  borderRadius: "32px",
  background: "transparent",
  border: "1px solid var(--header-input)",
  color: "var(--header-text)",
  placeItems: "center",
  fontSize: "12px",
  lineHeight: "16px",
}

export const searchIcon: CSSProperties = {
  position: "absolute",
  top: "12px",
  left: "12px",
  width: "24px",
  height: "24px",
  cursor: "pointer",
  transition: "all 0.3s ease",
}

export const deleteIcon: CSSProperties = {
  width: "24px",
  height: "24px",
  position: "absolute",
  fill: "var(--header-text)",
  top: "12px",
  right: "12px",
  transition: "opacity 0.3s ease",
}

export const filterForHeader = (isOpenFilter: boolean): CSSProperties => ({
  maxHeight: "85vh",
  position: "fixed",
  top: isOpenFilter ? "55vh" : "-275px",
  opacity: isOpenFilter ? 1 : 0,
  visibility: isOpenFilter ? "visible" : "hidden",
  left: "50%",
  zIndex: 999,
  transform: "translate(-50%, -50%)",
  transition: "all 0.3s ease",
})
