import { FC } from "react"
import s from "./styles.module.css"
import Select, { StylesConfig } from "react-select"

interface MyFormValues {
  type: string
  placeholder: string
  value: any
  handleChange: (e: any) => void
  name: string
  title?: string
  onBlur: (e: any) => void
  searchClick?: (e: any) => void
  onKeyPress?: (e: any) => void
  errorText: string | null
  isSearch?: boolean
  min?: string
  max?: string
  isLoading?: any
  defaultInputValue?: any
  onChange: (e: any) => void
  options: any
  onInputChange?: (e: string) => void
}

const customStyles: StylesConfig = {
  control: (styles: any, state) => {
    return {
      ...styles,
      minHeight: "40px",
      width: "100%",
      backgroundColor: "var(--inputs-bg)",
      borderRadius: "0.5rem",
      border: "none",
      borderBottom: state.isFocused
        ? "2px solid var(--filter-range-active)"
        : "2px solid transparent",
      outline: "none",
      boxShadow: "none",
      color: state.isFocused
        ? "var(--filter-range-active)"
        : "var(--secondary-text)",
      "&:hover": {},
      cursor: "pointer",
    }
  },
  singleValue: (styles: any, state) => {
    return {
      ...styles,
      color: "var(--secondary-color)",
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
    }
  },
  placeholder: (styles: any, state) => {
    return {
      ...styles,
      fontFamily: "Roboto, sans-serif",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "400",
      color: state.isFocused
        ? "var(--filter-range-active)"
        : "var(--secondary-text)",
    }
  },
  indicatorSeparator: (styles: any, state) => {
    return {
      ...styles,
      display: "none",
    }
  },
  dropdownIndicator: (styles: any, state) => {
    return {
      ...styles,
      color: state.isFocused
        ? "var(--filter-range-active)"
        : "var(--secondary-text)",
    }
  },
  loadingIndicator: (styles: any, state) => {
    return {
      ...styles,
      backgroundColor: "inherit",
    }
  },
  menu: (styles: any, state) => {
    return {
      ...styles,
      color: "#1c1b1f",
      borderRadius: "0.5rem",
      width: "100%",
      scrollbarWidth: "thin",
      scrollbarColor: "var(--unselected-text) transparent",
    }
  },
  option: (styles: any, state) => {
    return {
      ...styles,
      cursor: "pointer",
      width: "100%",
      backgroundColor: state.isFocused
        ? "var(--filter-range-active)"
        : "transparent",
      color: state.isFocused ? "var(--main-bg)" : "inherit",
    }
  },
}

const InputSelect: FC<MyFormValues> = ({
  onInputChange,
  errorText,
  placeholder,

  value,
  title = null,
  onChange,
  isLoading,
  options,
  defaultInputValue,
}) => {
  return (
    <div style={{ width: "100%" }}>
      {title && <span className={s.title}>{title}</span>}
      <div className={s.input_box}>
        <Select
          onInputChange={(e: string) => {
            if (onInputChange) {
              onInputChange(e)
            }
          }}
          isLoading={isLoading}
          onChange={onChange}
          value={value}
          styles={customStyles}
          options={options}
          placeholder={placeholder}
          defaultInputValue={defaultInputValue}
        />
      </div>
      {errorText && <p className={s.error_text}>{errorText}</p>}
    </div>
  )
}

export default InputSelect
