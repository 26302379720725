import { FC, PropsWithChildren, ReactNode } from "react"
import { Navigate } from "react-router-dom"

export type PrivateRouteProps = PropsWithChildren<{
  redirect: string
}>

export const ProtectedRouteOrganizer: FC<PrivateRouteProps> = ({
  children,
  redirect,
}): ReactNode => {
  const ls = localStorage.getItem("LocalCurrentUser")
  const isOrganizerRole = ls
    ? JSON.parse(atob(ls.split(".")[1]))?.role === "ORGANIZATION_ADMIN"
    : false

  return isOrganizerRole ? children : <Navigate to={redirect} />
}
