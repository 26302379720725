import React, { FC, useEffect } from "react";
import Google from "../../../assets/svg/googleIcon.svg";
import  { useGoogleLogin} from "@react-oauth/google"
import { useGoogleLoginMutation } from "../../../app/data/auth/authApi";

const GoogleAuthButton: FC = () => {
  const [fetchGoogleLogin, {error}] = useGoogleLoginMutation()
  const googleLogin = useGoogleLogin({
    onSuccess: (response) => {
     fetchGoogleLogin({
       code: response.code
     })
    },
    onError: (error) => {
      console.log(error);
    },
    flow: "auth-code"
  })
  useEffect(() => {
    if (error){
      console.log(error);
    }
  }, [error]);
  const clickHandler = () => {
    googleLogin()
  }
  return (<img onClick={clickHandler} src={Google} alt="google_icon" />)
}

export default GoogleAuthButton