import { ChangeEvent, useEffect, useState } from "react"
import { IMaskInput } from "react-imask"
import { PotentiaParticipantUserPreData, ViewsNames } from "../.."
import {
  UserDataForAddToBaseParticipant,
} from "../../../../../app/data/promotion/promotionApi"
import GradientButton, {
  GradienButtonStyles,
} from "../../../../GradientStateBtn"
import validation from "./validation"
import s from "./styles.module.scss"
import { SubModalMessages } from "../../sub_modal_message"

type AddNewUserProps = {
  userData: UserDataForAddToBaseParticipant
  onModalDeleteParticipant: (messageType: keyof typeof SubModalMessages) => void
  onSetVeiew: (popupVeiew: ViewsNames) => void
  onWriteUserData: (
    inputName: keyof PotentiaParticipantUserPreData,
    targetValue: string,
  ) => void
  onChangeSetPhone: (phoneNumbers: string) => void
  isEdit: boolean
  isChanged: boolean
  setChanged: (change: boolean) => void
}

const inputMaskPhone = "{7}(000)000-00-00"

const AddNewUser = ({
  userData,
  onSetVeiew,
  onWriteUserData,
  onChangeSetPhone,
  isEdit,
  onModalDeleteParticipant,
  isChanged,
  setChanged
}: AddNewUserProps) => {
  const {
    firstname,
    phone_number,
    lastname,
    telegram_username,
    event_id,
    potential_participant_id,
  } = userData
  const [isValid, setIsValid] = useState({
    firstname: true,
    lastname: true,
    telegram_username: true,
  })

  useEffect(() => {
    console.log(telegram_username, 'telega')
  },[telegram_username])

  const isValidTelegram =
    telegram_username.length === 0
      ? true
      : /^[A-Za-z\d_]{5,32}$/.test(telegram_username)

  const onClickBtnGoToView = (view: ViewsNames) => {
    onSetVeiew(view)
  }

  const onSetPhone = (evt: React.FormEvent<HTMLInputElement>) => {
    isEdit && setChanged(true)
    onChangeSetPhone(evt.currentTarget.value)
  }

  const onClickModalDeleteParticipant = () => {
    onModalDeleteParticipant("deleteParticipant")
  }

  useEffect(()=> {
    console.log(isEdit, isChanged)
  },[])

  const setIsDisabledBtn = () => {
    if (isEdit && !isChanged) {
      return true
    } else false
  
    if (
       !/^\d{1,3}\(\d{3}\)\d{3}-\d{2}-\d{2}$/.test(phone_number) ||
      firstname === "" ||
      lastname === "" ||
      (telegram_username.length > 0 &&
        !/^[A-Za-z\d_]{5,32}$/.test(telegram_username))
    ) {
      return true
    } else return false
  }

  const onChangeWriteUserData = (
    inputName: keyof PotentiaParticipantUserPreData,
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    isEdit && setChanged(true)
    if (evt.target.value === "") {
      onWriteUserData(inputName, evt.target.value)
    }

    if (validation(evt.target.value, "OnlyLetters")) {
      onWriteUserData(inputName, evt.target.value)
      isValidStyling(inputName, true)
    } else {
      isValidStyling(inputName, false)
    }
  }

  const telegramWriteToData = (
    inputName: keyof PotentiaParticipantUserPreData,
    evt: ChangeEvent<HTMLInputElement>,
  ) => {
    isEdit && setChanged(true)

    if (evt.target.value === "") {
      onWriteUserData(inputName, evt.target.value)
    }
    onWriteUserData(inputName, evt.target.value)
  }

  const isValidStyling = (inputName: string, thisValid: boolean) => {
    switch (inputName) {
      case "firstname":
        return setIsValid({ ...isValid, firstname: thisValid })
      case "lastname":
        return setIsValid({ ...isValid, lastname: thisValid })
      case "telegram_username":
        return setIsValid({ ...isValid, telegram_username: thisValid })
    }
  }

  const onClickGoToAccess = () => {
    isEdit && setChanged(false)
    onSetVeiew(ViewsNames.AccessNewUser)
  }

  return (
    <form
      action=""
      className={`${s.add_new_user_container} ${s.form}`}
      onSubmit={(e) => e.preventDefault()}
    >
      <ul className={s.add_new_user}>
        <li className={s.form__item}>
          <input
            className={s.form__input}
            placeholder="Имя"
            value={firstname}
            onChange={(e) => onChangeWriteUserData("firstname", e)}
          />
          <label hidden={isValid.firstname} className={s.form__label}>
            Только буквы*
          </label>
        </li>
        <div className={s.form__item}>
          <input
            className={s.form__input}
            placeholder="Фамилия"
            value={lastname}
            onChange={(e) => onChangeWriteUserData("lastname", e)}
          />
          <label hidden={isValid.lastname} className={s.form__label}>
            Только буквы*
          </label>
        </div>
        <div className={s.form__item}>
          <IMaskInput
            mask={inputMaskPhone}
            className={s.form__input}
            value={phone_number}
            onChange={(e) => onSetPhone(e)}
            placeholder="Номер телефона"
            defaultValue={phone_number}
          />
        </div>
        <div className={s.form__item}>
          <input
            className={s.form__input}
            value={telegram_username}
            defaultValue={telegram_username ?? ''}
            onChange={(e) => telegramWriteToData("telegram_username", e)}
            placeholder="@telegram"
            style={{ textTransform: "none" }}
          />
          <label hidden={isValidTelegram} className={s.form__label}>
            Символы: A-z (регистр не важен), 0-9 и "подчеркивание" Длина: 5-32.
            <br />
            Это не обязательное поле
          </label>
        </div>
        {/* Кнопка удаления участника при редактироании */}
        {isEdit && (
          <button
            className={s.delete_btn}
            onClick={() => onClickModalDeleteParticipant()}
          >
            Удалить
          </button>
        )}
      </ul>
      <div className={s.buttons_block}>
        <GradientButton<Object>
          onAction={() => onClickBtnGoToView(ViewsNames.SearchUser)}
          style={GradienButtonStyles.borderGradient}
          params={{ text: "Назад", disabled: false }}
        />
        <GradientButton<Object>
          onAction={() => onClickGoToAccess()}
          style={GradienButtonStyles.fullGradient}
          params={{
            text: "Далее",
            disabled: setIsDisabledBtn(),
          }}
        />
      </div>
    </form>
  )
}

export default AddNewUser
