import { useState, useEffect } from "react"
import {
  useGetUserInfoWritableQuery,
  useUpdateUserSettingsMutation,
} from "../../../app/data/userDataSlice/userApi"
import { ResponseStatus } from "../../../shared/user-profile/types"
import {
  UserInfoWritable,
  UserProfileUpdate,
} from "../../../app/data/userDataSlice/types"
import { IoError, ioErrorOf } from "../../../app/data/errors"
import { useAppSelector } from "../../../app/hooks"
import { selectUserId } from "../../../app/data/appTokensStore"

export type UpdateProfileArgs = Omit<UserProfileUpdate, "user_id">
export type PathProfile = {
  patch: (value: UpdateProfileArgs) => void
  isLoading: boolean
  error?: IoError
}

export type SettingEditor = {
  updateProfile: (nextState: Partial<UserProfileUpdate>) => void
  isLoading: boolean
  error?: IoError
  currentUser?: UserInfoWritable
  setUsername: (value: string) => void
  setLastName: (value: string) => void
  setFirstName: (value: string) => void
  setBirthday: (value: string) => void
  setInterests: (value: number[]) => void
  setGeolocationOn: (value: boolean) => void
}
const createInitialProfileState = (id?: number): UserProfileUpdate => ({
  avatars_filenames_for_deleting: [],
  birthday: "",
  first_name: "",
  interesting_event_type: [],
  is_geolocation_available: false,
  last_name: "",
  new_professions: [],
  profession_ids: [],
  user_avatar: "",
  user_id: id,
  username: "",
})
export const useProfileSetting = (): ResponseStatus<UserInfoWritable> => {
  const userId = useAppSelector(selectUserId)!
  const { data, error, isLoading } = useGetUserInfoWritableQuery({ userId })

  return { isLoading, error: ioErrorOf(error), state: data }
}

export const useUpdateProfileSetting = (): PathProfile => {
  const [updateProfile, { isLoading, error }] = useUpdateUserSettingsMutation()
  const handlePatch = (value: UpdateProfileArgs) => {
    updateProfile({ user_id: useAppSelector(selectUserId)!, ...value })
  }

  return {
    isLoading,
    error: ioErrorOf(error),
    patch: handlePatch,
  }
}

export const useProfileSettingEditor = (): SettingEditor => {
  const userId = useAppSelector(selectUserId)!
  const [profileState, setProfileState] = useState<UserProfileUpdate>(
    createInitialProfileState(userId),
  )
  const { data, error, isLoading } = useGetUserInfoWritableQuery({
    userId,
  })
  const [updateProfile] = useUpdateUserSettingsMutation()

  useEffect(() => {
    if (data) {
      setProfileState((profile) => ({
        ...profile,
        birthday: data.birthday,
        first_name: data.first_name,
        last_name: data.last_name,
        username: data.username,
        is_geolocation_available: data.is_geolocation_available,
        interesting_event_type: data.interesting_event_type.map(
          (event) => event.id,
        ),
      }))
    }
  }, [data])

  const handleUpdateProfile = (nextState: Partial<UserProfileUpdate>) =>
    updateProfile({ ...profileState, ...nextState })

  const handleUpdateField = <K extends keyof UserProfileUpdate>(
    key: K,
    value: UserProfileUpdate[K],
  ) => {
    if (value !== profileState[key]) {
      handleUpdateProfile({ [key]: value })
    }
  }

  return {
    updateProfile: handleUpdateProfile,
    isLoading,
    error: ioErrorOf(error),
    currentUser: data,
    setUsername: (value: string) => handleUpdateField("username", value),
    setLastName: (value: string) => handleUpdateField("last_name", value),
    setFirstName: (value: string) => handleUpdateField("first_name", value),
    setBirthday: (value: string) => handleUpdateField("birthday", value),
    setInterests: (value: number[]) =>
      handleUpdateField("interesting_event_type", value),
    setGeolocationOn: (value: boolean) =>
      handleUpdateField("is_geolocation_available", value),
  }
}
