// Selector для получения выбранных категорий
import { RootState } from "../../app/store";

export const selectSelectedCategories = (state: RootState) => state.categories.selectedCategories;
export const selectCategories = (state: RootState) => state.categories.categories;
export const selectLoading = (state: RootState) => state.categories.loading;
export const selectError = (state: RootState) => state.categories.error;
// Селектор для проверки, выбрана ли хотя бы одна категория
export const isAnyCategorySelected = (state: RootState): boolean => {
  return state.categories.selectedCategories.length > 0;
};
