import { SxProps, Theme } from "@mui/material"

export const cardBtnImGoing: SxProps<Theme> = {
  width: "100%",
  maxHeight: "100%",
  borderRadius: "16px",
  padding: "10px 8px 10px 8px",
  fontFamily: "Manrope, sans-serif",
  fontSize: "12px",
  lineHeight: "16px",
  fontWeight: 400,
  letterSpacing: "0.4px",
  textTransform: "uppercase",
  color: "var(--white)",
  background: "var(--btn-gradient)",
  transition: "all 0.6s ease",
  "&:hover": {
    textDecoration: "none",
    boxShadow: "var(--btns-hover-shadow)",
  },
}

export const btnCont: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  width: "100%",
  padding: 0,
  lineHeight: 1.2,
}
