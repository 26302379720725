import { FC } from "react"
import { Avatar, Box, Typography } from "@mui/material"
import AvatarFallback from "../../../assets/svg/header-icons-pack/avatar-empty-icon.svg?react"
import * as st from "./style"
import Pencil from "../../../assets/svg/promotion-icon/pencil"

export type EmployeeCardInfoProps = {
  id: number
  firstname: string
  lastname: string
  avatar_url: string
  username: string
  handleDeleteUser: (id: number) => void
}
export const EmployeeCardInfo: FC<EmployeeCardInfoProps> = ({
  username,
  id,
  lastname,
  firstname,
  avatar_url,
  handleDeleteUser,
}) => {
  return (
    <Box onClick={() => {}} sx={st.employeeCard}>
      <Box sx={st.employeeCardCont}>
        <Avatar
          src={avatar_url}
          alt={username || "avatar"}
          sx={st.employeeCardAvatar}
        >
          <AvatarFallback />
        </Avatar>
        <Box sx={st.employeeCardInfo}>
          {username && (
            <Typography
              variant="body1"
              fontWeight={500}
              color={"var(--primary-text)"}
            >
              {`@${username}`}
            </Typography>
          )}
          <Typography
            variant="body1"
            fontWeight={500}
            color={"var(--primary-text)"}
            textTransform="capitalize"
          >
            {firstname && firstname} {lastname && lastname}
            {!username && !firstname && !lastname ? "unknown" : ""}
          </Typography>
        </Box>
      </Box>

      <Box onClick={() => handleDeleteUser(id)} sx={st.employeeCardAction}>
        <Pencil color="var(--filter-range-active)" />

        <Typography
          variant="caption"
          color={"var(--filter-range-active)"}
          fontWeight={400}
          textTransform="uppercase"
        >
          Разжаловать
        </Typography>
      </Box>
    </Box>
  )
}
