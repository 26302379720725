import React, { FC, useState } from "react";
import { useGetLocationQuery } from "../../../app/data/location/locationApi";
import { geolocationStyle } from "../headerNavStyles";
import geolocationIcon from "/src/assets/svg/header-icons-pack/geolocation.svg"
import { Popover } from "@mui/material";
import GeoMenu from "../GeoMenu";
import useGeoProvider from "../../../shared/GeoProvider";


const GetButton:FC  = () => {
  const [ data ] = useGeoProvider()
  const  [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return <>


    <button onClick={handleClick} style={geolocationStyle}>
      <img src={geolocationIcon} alt="Геолокация" />
      <p>{data.city}</p>
    </button>
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <GeoMenu/>
    </Popover>

  </>
}

export default GetButton