import classNames from "classnames"
import s from "./styles.module.scss"
import { SendWhatsAppData, ViewsNames } from ".."
import { Messengers } from "../views/send_qr"
import { useEffect } from "react"

export const SubModalMessages = {
  addNew: {
    title: "Ой!",
    message:
      "Пользователь с такими данными уже существует в базе! Введите другие данные!",
  },
  repeatSendTicket: {
    title: "Выслать повторно?",
    message: `Данному пользователю ранее уже высылалось приглашение в telegram.
							Нажимая да, вы вышлите приглашение повторно.`,
  },
  deleteParticipant: {
    title: "",
    message: " из базы и вернуться к поиску участников?",
  },
  wasSend: {
    title: "Выслать повторно?",
    message: `Данному пользователю ранее уже высылалось приглашение в этот мессенджер.
							Нажимая да, вы вышлите приглашение повторно.`,
  },
  createTicket: {
    title: "Ой!",
    message: "Создать билет не получится, так как мероприятие уже прошло",
  },
  cantEdit: {
    title: "Нет доступа.",
    message: "Этого участника добавил кто-то другой.",
  },
  sendToWhatsApp: {
    title: 'Отправить через WhatsApp?',
    message: `После подтверждения отправки - билет 
              скопируется в буфер обмена и вас перенесет в WhatsApp`
  }


}

type SubModalMessageProps = {
  participantName: string
  isVisible: boolean
  setIsVisible: (isVisible: boolean) => void
  currenSubMessage: keyof typeof SubModalMessages
  onBackToView: (view: ViewsNames) => void
  onDeleteParticipant: () => void
  onSetMessangerForSendTicket: (messanger: Messengers | null) => void
  messanger?: Messengers | null
  setSendToWhatsApp: (value: SendWhatsAppData) => void
  ticket: string
}

const SubModalMessage = ({
  isVisible,
  setIsVisible,
  currenSubMessage,
  onBackToView,
  onDeleteParticipant,
  participantName,
  onSetMessangerForSendTicket,
  messanger,
  setSendToWhatsApp,
  ticket
}: SubModalMessageProps) => {
  const TitileForDelete = `Удалить ${participantName} ${SubModalMessages.deleteParticipant.message} `

  const onClickBackToView = () => {
    currenSubMessage === "addNew" ? onBackToView(ViewsNames.AddNewUser) : null
  }

  const onClickSendToWhatsApp = () => {
    const value: SendWhatsAppData =  {
      isSend: true,
      ticket: ticket
    }
    setSendToWhatsApp(value)
    setIsVisible(false)
  }

  const onClickSetMessangerForSendTicket = () => {
    messanger && onSetMessangerForSendTicket(messanger)
    onClickSetIsVisible()
  }
  useEffect(() => {
    console.log(currenSubMessage)
  }, [currenSubMessage])

  const onYesClickFunction = () => {
    currenSubMessage === "deleteParticipant"
      ? onClickDeleteParticipant()
      : currenSubMessage === "wasSend"
      ? onClickSetMessangerForSendTicket()
      : currenSubMessage === "sendToWhatsApp"
      ? onClickSendToWhatsApp()
      : null
  }

  const onNoClickFunction = () => {
    if (currenSubMessage === "wasSend") {
      onSetMessangerForSendTicket(null)
      onClickSetIsVisible()
    } else {
      onClickSetIsVisible()
    }
  }

  const onClickDeleteParticipant = () => {
    onDeleteParticipant()
  }

  const onBackOldEvent = () => {
    setIsVisible(!isVisible)
    onBackToView(ViewsNames.SearchUser)
  }

  const onClickSetIsVisible = () => {
    setIsVisible(!isVisible)
    onClickBackToView()
  }
  return (
    <div className={classNames(s.overlay, !isVisible && s.overlay_hidden)}>
      <div className={classNames(s.submodal, !isVisible && s.submodal_hidden)}>
        <div className={s.submodal_text}>
          <h4 className={s.submodal_title}>
            {SubModalMessages[currenSubMessage].title}
          </h4>
          <p className={s.submodal_message}>
            {currenSubMessage === "deleteParticipant"
              ? TitileForDelete
              : SubModalMessages[currenSubMessage].message}
          </p>
        </div>
        {currenSubMessage === "createTicket" && (
          <div className={s.submodal_buttons}>
            <button
              className={s.submodal_btn}
              onClick={() => onBackOldEvent()}
            >
              Назад
            </button>
          </div>
        )}
        {currenSubMessage === "addNew" && (
          <div className={s.submodal_buttons}>
            <button
              className={s.submodal_btn}
              onClick={() => onClickSetIsVisible()}
            >
              Назад
            </button>
          </div>
        )}
        {currenSubMessage === "cantEdit" && (
          <div className={s.submodal_buttons}>
            <button
              className={s.submodal_btn}
              onClick={() => onClickSetIsVisible()}
            >
              Назад
            </button>
          </div>
        )}
        {currenSubMessage === "wasSend" && (
          <div className={s.submodal_buttons}>
            <button
              className={s.submodal_btn}
              onClick={() => onYesClickFunction()}
            >
              Да
            </button>
            <button
              className={s.submodal_btn}
              onClick={() => onNoClickFunction()}
            >
              Нет
            </button>
          </div>
        )}

        {
          currenSubMessage === "sendToWhatsApp" && (
            <div className={s.submodal_buttons}>
              <button
                className={s.submodal_btn}
                onClick={() => onYesClickFunction()}
              >
                Да
              </button>
              <button
                className={s.submodal_btn}
                onClick={() => onNoClickFunction()}
              >
                Нет
              </button>
            </div>
          )
        }

        {currenSubMessage === "deleteParticipant" && (
          <div className={s.submodal_buttons}>
            <button
              className={s.submodal_btn}
              onClick={() => onYesClickFunction()}
            >
              Да
            </button>
            <button
              className={s.submodal_btn}
              onClick={() => onNoClickFunction()}
            >
              Нет
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default SubModalMessage
