import { Slider } from "@mui/material"
import ld from "lodash"
import { FC } from "react"
import s from "../styles.module.css"
import useEventFilter from "../../../shared/filter/hooks/useEventFilter";

export const filterCountPeopleConfig = {
  min:1,
  max: 120,
  step: 10
}
const title = (min: number | undefined, max: number | undefined) :string=> {
  const minSrt = min ? min.toString() : filterCountPeopleConfig.min.toString()
  const maxSrt = (max === undefined || max === filterCountPeopleConfig.max) ? "∞" : max.toString()
  return `Количество участников: от ${minSrt} до ${maxSrt}`
}
export const FilterMemberCount: FC = () => {
  const {currentFilter, updateParticipantsRange} = useEventFilter()
  const [min, max] = [currentFilter.numberOfParticipantsMin, currentFilter.numberOfParticipantsMax]
  const onChange = (_: Event, v: number | number[]) => {
    if (ld.isArray(v)) {
      const [newMin, newMax] = v

      const minArg = newMin === filterCountPeopleConfig.min ? undefined : newMin
      const maxArg = newMax === filterCountPeopleConfig.max? undefined : newMax
      updateParticipantsRange([minArg, maxArg])
    }
  }

  return (
    <div className={s.category_box}>
      <div className={s.category_box_title}>
        <h4 className={s.title}>
          {title(min, max)}
        </h4>
      </div>

      <div className={s.box}>
        <Slider
          min={filterCountPeopleConfig.min}
          max={filterCountPeopleConfig.max}
          step={filterCountPeopleConfig.step}
          marks
          sx={{
            color: "var(--filter-range-active)",
            marginLeft: "10.5px",
            width: `calc(100% - 21px)`,
            padding: "16px 0",
          }}
          getAriaLabel={() => "Minimum participants"}
          value={[min ?? filterCountPeopleConfig.min, max ?? filterCountPeopleConfig.max]}
          onChange={onChange}
          valueLabelDisplay="auto"
          disableSwap
        />
      </div>
    </div>
  )
}
