import { SxProps } from "@mui/material"
import { Theme } from "@mui/system"

export const feedPageCont = {
  height: "100%",
  width: "100%",
}

export const feedCardsCont = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(302px, 589px))",
  rowGap: "12px",
  columnGap: "8px",
  height: "100%",
  marginBottom: "16px",

  "@media (max-width: 638px)": {
    placeItems: "center",
  },

  "@media (min-width: 1024px)": {
    gridTemplateColumns: "repeat(auto-fill, minmax(316px, 1fr))",
  },
}

export const feedPaginCont: SxProps<Theme> = {
  alignItems: "center",
  marginBottom: "16px",
  padding: "0 5px",

  "@media (min-width: 638px)": {
    padding: "0px 32px",
  },

  "& .MuiPagination-ul": {
    display: "flex",
    justifyContent: "center",
  },

  "& .MuiPaginationItem-root": {
    marginBottom: "5px",
    background: "transparent",
    border: "1px solid var(--borders)",
    overflow: "hidden",
    color: "var(--primary-text)",

    "&:hover": {
      background: "var(--btns-hover)",
    },

    "&.Mui-selected": {
      background: "var(--btns-selected) !important",
    },
  },
}
